import React, { useState } from "react";
import StatesList from "../../Assets/StatesList.js";
import LoadingSpinner from "../LoadingSpinner.js";
import { getCustomerExists } from "../../Adapters/CustomerAdapters.js";
import "../../Styles/AccountPage/Contacts.scss";
import { CustomerContact } from "../../Assets/UtilityClasses.js";
import { postCustomerRegistration } from "../../Adapters/AuthAdapters.js";

const AddCustomer = (props) => {
  const [basicCustomerInfo, setBasicCustomerInfo] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const [customerContact, setCustomerContact] = useState(new CustomerContact());

  const handleSubmit = (e) => {
    e.preventDefault();

    const parsedCustomerInfo = {
      ...basicCustomerInfo,
      customerNumber: parseInt(basicCustomerInfo.customerNumber),
      phoneNumber: parseInt(basicCustomerInfo.phoneNumber),
      faxNumber: parseInt(basicCustomerInfo.faxNumber),
      zipCode: parseInt(basicCustomerInfo.zipCode),
    };
    const parsedCustomerContact = {
      ...customerContact,
      phoneNumber: parseInt(customerContact.phoneNumber),
    };

    postCustomerRegistration({
      customer: parsedCustomerInfo,
      contact: parsedCustomerContact,
    });
  };

  const checkEmail = () => {
    if (/(@)(.+)$/gm.test(customerContact.email)) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "email";
        })
      );
    } else {
      setErrorArray([...errorArray, "email"]);
    }
  };

  const checkPhoneNumber = () => {
    if (/^[0-9]{10}$/gm.test(customerContact.phoneNumber)) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "phoneNumber";
        })
      );
    } else {
      if (!errorArray.includes("phoneNumber")) {
        setErrorArray([...errorArray, "phoneNumber"]);
      }
    }
  };
  const checkSomeValue = (name) => {
    if (customerContact[name].length === 0) {
      if (!errorArray.includes(name)) {
        setErrorArray([...errorArray, name]);
      }
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== name;
        })
      );
    }
  };

  const checkCustomerIfExists = async () => {
    const customerExists = await getCustomerExists(
      basicCustomerInfo.customerNumber
    );
    if (customerExists.data) {
      setErrorArray([...errorArray, "customerNumber already exists"]);
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "customerNumber already exists";
        })
      );
    }
  };
  return (
    <section className="editUsersForm">
      {true ? (
        <>
          <ul className={errorArray.length > 0 ? "errors" : "hidden"}>
            {errorArray.map((err) => {
              return <li>{err}</li>;
            })}
          </ul>
          <form onSubmit={(e) => {}}>
            <h2>Customer Info</h2>

            <label htmlFor="customerNumber">Customer Number:</label>
            <input
              name="customerNumber"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  customerNumber: e.target.value,
                });
              }}
              onBlur={() => {
                checkCustomerIfExists();
              }}
            />

            <label htmlFor="password">Password:</label>
            <input
              type="password"
              name="password"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  password: e.target.value,
                });
              }}
            />

            <label htmlFor="name">Name:</label>
            <input
              name="name"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  name: e.target.value,
                });
              }}
            />

            <label htmlFor="address">Address:</label>
            <input
              name="address"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  address: e.target.value,
                });
              }}
            />

            <label htmlFor="city">City:</label>
            <input
              name="city"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  city: e.target.value,
                });
              }}
            />

            <label htmlFor="state">State:</label>
            <select
              defaultValue
              name="state"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  state: e.target.value,
                });
              }}
            >
              <option disabled value>
                {" "}
                -- select an option --{" "}
              </option>
              {StatesList.map((state) => {
                return <option key={state}>{state}</option>;
              })}
            </select>

            <label htmlFor="phoneNumber">Phone Number:</label>
            <input
              name="phoneNumber"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  phoneNumber: e.target.value,
                });
              }}
            />
            <label htmlFor="faxNumber"> Fax Number:</label>
            <input
              name="faxNumber"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  faxNumber: e.target.value,
                });
              }}
            />

            <label htmlFor="zipCode"> Zipcode:</label>
            <input
              name="zipCode"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  zipCode: e.target.value,
                });
              }}
            />
          </form>
          <h2>Customer Contact</h2>
          <input
            name="name"
            placeholder="Schmitty Werbenjagermanjensen"
            onChange={(e) => {
              setCustomerContact({
                ...customerContact,
                name: e.target.value,
              });
            }}
            onBlur={() => {
              checkSomeValue("name");
            }}
          />
          <label htmlFor="phoneNumber">Phone number:</label>
          <input
            name="phoneNumber"
            placeholder="1234567890"
            onChange={(e) => {
              setCustomerContact({
                ...customerContact,
                phoneNumber: e.target.value,
              });
            }}
            onBlur={() => {
              checkPhoneNumber();
            }}
          />
          <label htmlFor="email">Email:</label>
          <input
            name="email"
            placeholder="schmittyw@numberone.com"
            onChange={(e) => {
              setCustomerContact({
                ...customerContact,
                email: e.target.value,
              });
            }}
            onBlur={() => {
              checkEmail();
            }}
          />
          <p>
            All customers are added with a password of "@CTSisthebest!". Please
            remind the customer to change password.
          </p>
          <button onClick={handleSubmit}> Create Customer </button>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </section>
  );
};

export default AddCustomer;
