import React, { useState, useEffect } from "react";
import {
  authInsertProduct,
  getAllCategories,
} from "../../Adapters/ProductAdapters.js";
import { getAllVendors } from "../../Adapters/VendorAdapters.js";
import "../../Styles/AdminPage/AddItems.scss";

const AddItems = (props) => {
  const [fullProductDetails, setFullProductDetails] = useState({
    product: {
      productId: "",
      auxilliaryItemId: "",
      description: "",
      vendorKey: "",
      vendorItemNumber: "",
      standardCost: "",
      basePrice: "",
      minimumQuantity: "",
      maximumQuantity: "",
      defaultQuantity: "",
      imageAddress: "",
      categoryKey: "",
      countryOfOrigin: "",
    },
  });
  const [vendors, setVendors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const [typeAheadText, setTypeAheadText] = useState("");
  const [vendorSelected, setVendorSelected] = useState(false);

  useEffect(() => {
    const getvendorsandcategories = async () => {
      const vendorList = await getAllVendors();
      const categoryList = await getAllCategories();

      setVendors(vendorList.data);
      setCategories(categoryList.data);
    };
    getvendorsandcategories();
  }, []);

  const matchVendor = (name) => {
    return vendors.filter((vendor) => {
      return vendor.name === name;
    })[0];
  };

  const matchCategory = (name) => {
    return categories.filter((category) => {
      return category.name === name;
    })[0];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validValues()) {
      const newProduct = {
        ...fullProductDetails.product,
        standardCost: parseFloat(fullProductDetails.product.standardCost),
        vendorKey: parseInt(fullProductDetails.product.vendor.key),
        categoryKey: parseInt(fullProductDetails.product.category.key),
        basePrice: parseFloat(fullProductDetails.product.basePrice),
        minimumQuantity: parseInt(fullProductDetails.product.minimumQuantity),
        maximumQuantity: parseInt(fullProductDetails.product.maximumQuantity),
        defaultQuantity: parseInt(fullProductDetails.product.defaultQuantity),
      };

      try {
        await authInsertProduct(newProduct);
        window.alert("Success!");
        setFullProductDetails({
          product: {
            productId: "",
            auxilliaryItemId: "",
            description: "",
            vendorKey: "",
            vendorItemNumber: "",
            standardCost: "",
            basePrice: "",
            minimumQuantity: "",
            maximumQuantity: "",
            defaultQuantity: "",
            imageAddress: "",
            categoryKey: "",
            countryOfOrigin: "",
          },
        });
      } catch (err) {
        console.err(err);
        window.alert("Something went wrong!");
      }
    }
  };

  const validValues = () => {
    let inputs = document.querySelectorAll(".editItemForm input");
    let empties = [];

    inputs.forEach((cv) => {
      if (!cv.value) {
        empties.push(cv);
      }
    });

    return errorArray.length === 0 && empties.length === 0;
  };

  return (
    <section className="editItemForm">
      <>
        <h2> Add Product details:</h2>
        <ul className={errorArray.length > 0 ? "errors" : "hidden"}>
          {errorArray.map((err) => {
            return <li>{err}</li>;
          })}
        </ul>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <label htmlFor="productId">Product Id</label>
          <input
            name="productId"
            onChange={(e) => {
              setFullProductDetails({
                ...fullProductDetails,
                product: {
                  ...fullProductDetails.product,
                  productId: e.target.value,
                },
              });
            }}
            onBlur={() => {
              if (fullProductDetails.product.productId.length < 1) {
                if (!errorArray.includes("productId")) {
                  setErrorArray([...errorArray, "productId"]);
                }
              } else {
                setErrorArray(
                  errorArray.filter((err) => {
                    return err !== "productId";
                  })
                );
              }
            }}
          />

          <label htmlFor="auxilliaryItemId">Auxilliary Item Id</label>
          <input
            name="auxilliaryItemId"
            onChange={(e) => {
              setFullProductDetails({
                ...fullProductDetails,
                product: {
                  ...fullProductDetails.product,
                  auxilliaryItemId: e.target.value,
                },
              });
            }}
            onBlur={() => {
              if (fullProductDetails.product.auxilliaryItemId.length < 1) {
                if (!errorArray.includes("auxilliaryItemId")) {
                  setErrorArray([...errorArray, "auxilliaryItemId"]);
                }
              } else {
                setErrorArray(
                  errorArray.filter((err) => {
                    return err !== "auxilliaryItemId";
                  })
                );
              }
            }}
          />

          <label htmlFor="description">Description</label>
          <input
            name="description"
            onChange={(e) => {
              setFullProductDetails({
                ...fullProductDetails,
                product: {
                  ...fullProductDetails.product,
                  description: e.target.value,
                },
              });
            }}
            onBlur={() => {
              if (fullProductDetails.product.description.length < 1) {
                if (!errorArray.includes("description")) {
                  setErrorArray([...errorArray, "description"]);
                }
              } else {
                setErrorArray(
                  errorArray.filter((err) => {
                    return err !== "description";
                  })
                );
              }
            }}
          />

          <label htmlFor="vendor">Vendor</label>
          <input
            className="typeahead-input"
            type="text"
            placeholder="Begin typing vendor name..."
            value={typeAheadText}
            onChange={(e) => {
              setTypeAheadText(e.target.value.toUpperCase());
              if (vendorSelected) {
                setVendorSelected(false);
              }
            }}
          />
          <ul className="typeahead">
            {!vendorSelected
              ? vendors
                  .filter((vendor) => {
                    return typeAheadText
                      ? vendor.name.includes(typeAheadText)
                      : false;
                  })
                  .map((vendor) => {
                    return (
                      <li
                        key={Math.random() + vendor.name}
                        onClick={() => {
                          setFullProductDetails({
                            ...fullProductDetails,
                            product: {
                              ...fullProductDetails.product,
                              vendor: matchVendor(vendor.name),
                            },
                          });
                          setTypeAheadText(vendor.name);
                          setVendorSelected(true);
                        }}
                      >
                        {vendor.name}
                      </li>
                    );
                  })
              : ""}
          </ul>

          <label htmlFor="vendorItemNumber">Vendor Item Number</label>
          <input
            name="vendorItemNumber"
            onChange={(e) => {
              setFullProductDetails({
                ...fullProductDetails,
                product: {
                  ...fullProductDetails.product,
                  vendorItemNumber: e.target.value,
                },
              });
            }}
            onBlur={() => {
              if (fullProductDetails.product.vendorItemNumber.length < 1) {
                if (!errorArray.includes("vendorItemNumber")) {
                  setErrorArray([...errorArray, "vendorItemNumber"]);
                }
              } else {
                setErrorArray(
                  errorArray.filter((err) => {
                    return err !== "vendorItemNumber";
                  })
                );
              }
            }}
          />

          <label htmlFor="standardCost">Standard Cost</label>
          <input
            name="standardCost"
            onChange={(e) => {
              setFullProductDetails({
                ...fullProductDetails,
                product: {
                  ...fullProductDetails.product,
                  standardCost: e.target.value,
                },
              });
            }}
            onBlur={() => {
              if (fullProductDetails.product.standardCost.length < 1) {
                if (!errorArray.includes("standardCost")) {
                  setErrorArray([...errorArray, "standardCost"]);
                }
              } else {
                setErrorArray(
                  errorArray.filter((err) => {
                    return err !== "standardCost";
                  })
                );
              }
            }}
          />

          <label htmlFor="basePrice">Base Price</label>
          <input
            name="basePrice"
            onChange={(e) => {
              setFullProductDetails({
                ...fullProductDetails,
                product: {
                  ...fullProductDetails.product,
                  basePrice: e.target.value,
                },
              });
            }}
            onBlur={() => {
              if (fullProductDetails.product.basePrice.length < 1) {
                if (!errorArray.includes("basePrice")) {
                  setErrorArray([...errorArray, "basePrice"]);
                }
              } else {
                setErrorArray(
                  errorArray.filter((err) => {
                    return err !== "basePrice";
                  })
                );
              }
            }}
          />

          <label htmlFor="minimumQuantity">Minumum Quantity</label>
          <input
            name="minimumQuantity"
            onChange={(e) => {
              setFullProductDetails({
                ...fullProductDetails,
                product: {
                  ...fullProductDetails.product,
                  minimumQuantity: e.target.value,
                },
              });
            }}
            onBlur={() => {
              if (fullProductDetails.product.minimumQuantity.length < 1) {
                if (!errorArray.includes("minimumQuantity")) {
                  setErrorArray([...errorArray, "minimumQuantity"]);
                }
              } else {
                setErrorArray(
                  errorArray.filter((err) => {
                    return err !== "minimumQuantity";
                  })
                );
              }
            }}
          />

          <label htmlFor="maximumQuantity">Maxmimum Quantity</label>
          <input
            name="maximumQuantity"
            onChange={(e) => {
              setFullProductDetails({
                ...fullProductDetails,
                product: {
                  ...fullProductDetails.product,
                  maximumQuantity: e.target.value,
                },
              });
            }}
            onBlur={() => {
              if (fullProductDetails.product.maximumQuantity.length < 1) {
                if (!errorArray.includes("maximumQuantity")) {
                  setErrorArray([...errorArray, "maximumQuantity"]);
                }
              } else {
                setErrorArray(
                  errorArray.filter((err) => {
                    return err !== "maximumQuantity";
                  })
                );
              }
            }}
          />

          <label htmlFor="defaultQuantity">Default Quantity</label>
          <input
            name="defaultQuantity"
            onChange={(e) => {
              setFullProductDetails({
                ...fullProductDetails,
                product: {
                  ...fullProductDetails.product,
                  defaultQuantity: e.target.value,
                },
              });
            }}
            onBlur={() => {
              if (fullProductDetails.product.defaultQuantity.length < 1) {
                if (!errorArray.includes("defaultQuantity")) {
                  setErrorArray([...errorArray, "defaultQuantity"]);
                }
              } else {
                setErrorArray(
                  errorArray.filter((err) => {
                    return err !== "defaultQuantity";
                  })
                );
              }
            }}
          />

          <label htmlFor="imageAddress">Image Address</label>
          <input
            name="imageAddress"
            onChange={(e) => {
              setFullProductDetails({
                ...fullProductDetails,
                product: {
                  ...fullProductDetails.product,
                  imageAddress: e.target.value,
                },
              });
            }}
            onBlur={() => {
              if (fullProductDetails.product.imageAddress.length < 1) {
                if (!errorArray.includes("imageAddress")) {
                  setErrorArray([...errorArray, "imageAddress"]);
                }
              } else {
                setErrorArray(
                  errorArray.filter((err) => {
                    return err !== "imageAddress";
                  })
                );
              }
            }}
          />

          <label htmlFor="category">Category</label>
          <select
            defaultValue
            onChange={(e) => {
              setFullProductDetails({
                ...fullProductDetails,
                product: {
                  ...fullProductDetails.product,
                  category: matchCategory(e.target.value),
                },
              });
            }}
          >
            <option disabled value>
              {" "}
              -- select an option --{" "}
            </option>
            {categories.map((category) => {
              return <option key={category.name}>{category.name}</option>;
            })}
          </select>

          <label htmlFor="countryOfOrigin">Country Of Origin</label>
          <input
            name="countryOfOrigin"
            onChange={(e) => {
              setFullProductDetails({
                ...fullProductDetails,
                product: {
                  ...fullProductDetails.product,
                  countryOfOrigin: e.target.value,
                },
              });
            }}
          />
          <button className={validValues() ? "" : "disabled"} type="submit">
            Add Item
          </button>
        </form>
      </>
    </section>
  );
};

export default AddItems;
