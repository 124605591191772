import React, { useState, useEffect } from "react";

import { authGetAllPurchasingAgents } from "../../Adapters/PurchasingAgentAdapters";

const EditPurchasingAgentsSearch = (props) => {
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    const getagents = async () => {
      const agentlist = await authGetAllPurchasingAgents();
      setAgents(agentlist.data);
    };
    getagents();
  }, []);

  return (
    <section className="editAgents">
      <div className="agentsList">
        <table className="agentTable">
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {agents.map((agent) => {
              return (
                <tr
                  key={agent.key}
                  className="tableList"
                  onClick={() => {
                    props.setAgent(agent);
                  }}
                >
                  <td>{agent.name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default EditPurchasingAgentsSearch;
