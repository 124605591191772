import React, { useState } from "react";

import {
    authUpdatePurchasingAgent,
    authDeletePurchasingAgent,
} from "../../Adapters/PurchasingAgentAdapters";

const EditPurchasingAgentsForm = (props) => {
    const [agent, setAgent] = useState(props.agent);
    const [errorArray, setErrorArray] = useState([]);

    const submittedAlert = () => {
        window.alert("Submitted succesfully");
    };

    const goBack = () => {
        props.setAgent({});
    };

    const updateAgent = async (e) => {
        e.preventDefault();
        await authUpdatePurchasingAgent({
            key: agent.key,
            name: agent.name,
            phoneNumber: parseFloat(agent.phoneNumber),
            email: agent.email,
        });
        submittedAlert();
        goBack();
    };

    const deleteAgent = async () => {
        const confirm = window.confirm(
            "Are you sure you want to delete this agent?"
        );
        if (confirm) {
            try {
                await authDeletePurchasingAgent(props.agent.key);
            } catch (err) {
                console.error(err);
                window.alert(
                    "Error deleting this agent. There is likely (a) vendor(s) associated."
                );
            } finally {
                goBack();
            }
        }
    };

    const checkSomeValue = () => {
        if (agent.name.length === 0) {
            if (!errorArray.includes("name")) {
                setErrorArray([...errorArray, "name"]);
            }
        } else {
            setErrorArray(
                errorArray.filter((err) => {
                    return err !== "name";
                })
            );
        }
    };

    const checkEmail = () => {
        if (/(@)(.+)$/gm.test(agent.email)) {
            setErrorArray(
                errorArray.filter((err) => {
                    return err !== "email";
                })
            );
        } else {
            setErrorArray([...errorArray, "email"]);
        }
    };

    const checkPhoneNumber = () => {
        if (/^[0-9]{10}$/gm.test(agent.phoneNumber)) {
            setErrorArray(
                errorArray.filter((err) => {
                    return err !== "phoneNumber";
                })
            );
        } else {
            if (!errorArray.includes("phoneNumber")) {
                setErrorArray([...errorArray, "phoneNumber"]);
            }
        }
    };

    return (
        <section className="editAgentsForm">
            <>
                <ul className={errorArray.length > 0 ? "errors" : "hidden"}>
                    {errorArray.map((err) => {
                        return <li>{err}</li>;
                    })}
                </ul>
                <button
                    onClick={() => {
                        goBack();
                    }}
                >
                    ←
                </button>
                <form
                    onSubmit={(e) => {
                        updateAgent(e);
                    }}
                >
                    <h2>Agent Info</h2>
                    <label htmlFor="name">Name:</label>
                    <input
                        value={agent.name}
                        name="name"
                        onChange={(e) => {
                            setAgent({ ...agent, name: e.target.value });
                        }}
                        onBlur={(e) => {
                            checkSomeValue();
                        }}
                    />
                    <label htmlFor="phoneNumber">Phone number:</label>
                    <input
                        value={agent.phoneNumber}
                        name="phoneNumber"
                        onChange={(e) => {
                            setAgent({ ...agent, phoneNumber: e.target.value });
                        }}
                        onBlur={(e) => {
                            checkPhoneNumber();
                        }}
                    />
                    <label htmlFor="email">Email:</label>
                    <input
                        value={agent.email}
                        name="email"
                        onChange={(e) => {
                            setAgent({ ...agent, email: e.target.value });
                        }}
                        onBlur={(e) => {
                            checkEmail();
                        }}
                    />

                    <button
                        className={errorArray.length > 0 ? "disabled" : ""}
                        type="submit"
                    >
                        Submit changes
                    </button>
                </form>

                <h3>DANGER ZONE</h3>
                <button
                    onClick={() => {
                        deleteAgent();
                    }}
                >
                    Delete agent
                </button>
            </>
        </section>
    );
};

export default EditPurchasingAgentsForm;
