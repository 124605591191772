import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { authGetAllOrders } from "../../Adapters/OrderAdapters.js";

import "../../Styles/AdminPage/AllOrders.scss";

const AllOrders = (props) => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");

  useEffect(() => {
    const getorders = async () => {
      const orderList = await authGetAllOrders();
      setOrders(orderList.data);
      setFilteredOrders(orderList.data);
    };
    getorders();
  }, []);

  const getDate = (date) => {
    const newDate = new Date(0);
    newDate.setSeconds(date);
    return newDate.toLocaleDateString();
  };

  return (
    <section className="adminOrderHistory">
      <div>
        <h2>Order History</h2>
        <p>Search by order number, customer number, customer name, or date</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setFilteredOrders(
              orders.filter((order) => {
                return (
                  order.orderKey === parseInt(searchFilter) ||
                  order.customerName.includes(searchFilter) ||
                  order.customerNumber === parseInt(searchFilter) ||
                  getDate(order.date).includes(searchFilter)
                );
              })
            );
            setSearchFilter("");
          }}
        >
          <label htmlFor="filter">Search filter</label>
          <input
            type="text"
            name="filter"
            value={searchFilter}
            onChange={(e) => {
              setSearchFilter(e.target.value);
            }}
          />
          <button type="submit">→</button>
          <button
            onClick={() => {
              setFilteredOrders(orders);
            }}
          >
            Reset
          </button>
        </form>
      </div>
      <div className="tableContainer">
        <table>
          <thead>
            <tr>
              <th>Order Number</th>
              <th>Customer Number</th>
              <th>Customer Name</th>
              <th>Order Date</th>
              <th>Order Total</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order) => {
              return (
                <tr
                  key={order.orderKey}
                  onClick={() => {
                    props.history.push("/historicalorder/" + order.orderKey);
                  }}
                >
                  <td>{order.orderKey}</td>
                  <td>{order.customerNumber}</td>
                  <td>{order.customerName}</td>
                  <td>{getDate(order.date)}</td>
                  <td>${order.total}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default withRouter(AllOrders);
