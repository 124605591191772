import React from "react";

import Carousel from "../Components/Carousel.js";
import FeaturedCategories from "../Components/FeaturedCategories.js";
import CharityBanner from "../Components/CharityBanner.js"

import "../Styles/HomePage.scss";

const HomePage = () => {
  return (
    <section className="homePage">
      <div className="homePage-featuredProducts">
        <h2>Featured products</h2>
        <Carousel />
      </div>
          <FeaturedCategories />
          <CharityBanner />
    </section>
  );
};

export default HomePage;
