import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import ProductList from "../Components/ProductList.js";
import LoadingSpinner from "../Components/LoadingSpinner.js";
import { getProductsByCategory } from "../Adapters/ProductAdapters.js";

import "../Styles/CategoryProductPage.scss";

const CategoryProductPage = (props) => {
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState({});
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    const getdetails = async () => {
      const productsAndCategory = await getProductsByCategory(
        props.match.params.categoryKey
      );

      if (productsAndCategory.data.products.length === 0) {
        setNoResults(true);
      }

      setProducts(productsAndCategory.data.products);
      setCategory(productsAndCategory.data.category);
    };
    getdetails();
  }, [props.match.params.categoryKey]);
  return (
    <>
      {products && products.length > 0 ? (
        <section className="categoryProductPage">
          <span>
            <Link to="/">Home</Link> {"→"} {category.name}
          </span>
          <h2>Viewing category results for {category.name}</h2>
          <ProductList products={products} />
        </section>
      ) : noResults ? (
        <h3 className="noResults">
          Oops, there doesn't seem to be any results for: {category.name}
        </h3>
      ) : (
        <LoadingSpinner height="300px" />
      )}
    </>
  );
};

export default CategoryProductPage;
