import React, { useState } from "react";

import NoImage from "../../Assets/NoImage.js";
import { getProductsBySearchTerm } from "../../Adapters/ProductAdapters.js";

const EditItemsSearch = (props) => {
  const [products, setProducts] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  const itemSearch = async (e) => {
    e.preventDefault();
    const items = await getProductsBySearchTerm(searchValue);
    setProducts(items.data);
  };

  return (
    <section className="editItems">
      <div className="itemSearch">
        <form
          onSubmit={(e) => {
            itemSearch(e);
          }}
        >
          <input
            type="text"
            placeholder="Search entire store here"
            onChange={(e) => {
              handleChange(e);
            }}
            value={searchValue}
          />
          <button type="submit">{"→"}</button>
        </form>
      </div>
      <div className="itemList">
        {products.map((product) => {
          return (
            <div
              className="product"
              key={product.key}
              onClick={() => {
                props.setProduct(product);
              }}
            >
              <img
                src={product.imageAddress ? product.imageAddress : NoImage()}
                alt="Product"
              />
              <h3>{product.description ? product.description : "N/A"}</h3>
              <p>Item ID: {product.productId ? product.productId : "N/A"}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default EditItemsSearch;
