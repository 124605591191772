import React, { useState, useEffect, useContext } from "react";

import {
  CustomerRegistration,
  CustomerContact,
} from "../Assets/UtilityClasses.js";
import { postCustomerRegistration } from "../Adapters/AuthAdapters.js";
import UserContext from "../Contexts/UserContext.js";
import RegistrationStep1 from "../Components/Registration/RegistrationStep1.js";
import RegistrationStep2 from "../Components/Registration/RegistrationStep2.js";
import LoadingSpinner from "../Components/LoadingSpinner.js";
import { ErrorStatus } from "../Assets/UtilityClasses.js";
import ErrorMessage from "../Components/ErrorMessage.js";

import "../Styles/Registration.scss";

const Registration = (props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [customer, setCustomer] = useState(new CustomerRegistration());
  const [contact, setContact] = useState(new CustomerContact());
  const { setUser } = useContext(UserContext);
  const [errorStatus, setErrorStatus] = useState(new ErrorStatus());

  useEffect(() => {
    switch (pageNumber) {
      case 2:
        const parsedCustomer = customer;
        parsedCustomer.customerNumber = parseInt(parsedCustomer.customerNumber);
        parsedCustomer.phoneNumber = parseInt(parsedCustomer.phoneNumber);
        parsedCustomer.faxNumber = parseInt(parsedCustomer.faxNumber);
        parsedCustomer.zipCode = parseInt(parsedCustomer.zipCode);
        parsedCustomer.faxNumber.length > 0
          ? (parsedCustomer.faxNumber = parseInt(parsedCustomer.faxNumber))
          : delete parsedCustomer.faxNumber;

        setCustomer(parsedCustomer);
        break;
      case 3:
        const parsedContact = contact;
        parsedContact.phoneNumber = parseInt(parsedContact.phoneNumber);

        postCustomerRegistration({ customer, contact: parsedContact })
          .then((res) => {
            setUser({
              ...res.data,
              isAdmin: res.data.user.pricingTier === 42069,
            });
            setErrorStatus(new ErrorStatus());
            props.history.push("/");
          })
          .catch((err) => {
            setPageNumber(4);
            setErrorStatus({
              status: true,
              code: err.response.data.status,
              message: err.response.data.title,
            });
          });
        break;
      default:
        return;
    }
  }, [pageNumber]);

  switch (pageNumber) {
    case 1:
      return (
        <section className="registration">
          <RegistrationStep1
            customer={customer}
            setCustomer={(cust) => {
              setCustomer(cust);
            }}
            setPageNumber={(page) => {
              setPageNumber(page);
            }}
          />
        </section>
      );
    case 2:
      return (
        <section className="registration">
          <RegistrationStep2
            contact={contact}
            setContact={(cust) => {
              setContact(cust);
            }}
            setPageNumber={(page) => {
              setPageNumber(page);
            }}
          />
        </section>
      );
    case 3:
      return (
        <section className="registration">
          <LoadingSpinner height="300px" />
        </section>
      );
    case 4:
      return (
        <section className="registration">
          <ErrorMessage error={errorStatus} />
          <button
            onClick={() => {
              setPageNumber(2);
            }}
          >
            ← Go back
          </button>
        </section>
      );
    default:
      return "";
  }
};

export default Registration;
