import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import ProductListView from "./ProductListView.js";
import LoadingSpinner from "./LoadingSpinner.js";
import { getFeaturedProducts } from "../Adapters/ProductAdapters.js";

import "../Styles/Carousel.scss";

const Carousel = () => {
  const [currentMaxIndex, setCurrentMaxIndex] = useState(4);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getproducts = async () => {
      const products = await getFeaturedProducts();
      setProducts(products.data);
    };
    getproducts();
  }, []);

  const traverseLeft = () => {
    if (currentMaxIndex > 4) {
      setCurrentMaxIndex(currentMaxIndex - 1);
    }
  };

  const traverseRight = () => {
    if (currentMaxIndex < products.length) {
      setCurrentMaxIndex(currentMaxIndex + 1);
    }
  };

  const isCycleable = products.length > 4;
  const isListStart = currentMaxIndex - 4 === 0;
  const isListEnd = currentMaxIndex === products.length;

  const productsToShow = isCycleable
    ? products.slice(currentMaxIndex - 4, currentMaxIndex)
    : products;

  return (
    <>
      {products && products.length > 0 ? (
        <section className="carousel">
          <div className="carousel-products">
            <button
              className={isCycleable && !isListStart ? "left" : "left hidden"}
              onClick={() => {
                traverseLeft();
              }}
            >
              ←
            </button>
            {productsToShow.map((cv) => {
              return (
                <motion.div key={cv.productId}>
                  <ProductListView product={cv} key={cv.productId} />
                </motion.div>
              );
            })}
            <button
              className={isCycleable && !isListEnd ? "right" : "right hidden"}
              onClick={() => {
                traverseRight();
              }}
            >
              →
            </button>
          </div>
        </section>
      ) : (
        <LoadingSpinner height="300px" />
      )}
    </>
  );
};

export default Carousel;
