import React, { useState, useEffect } from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

const RegistrationStep2 = (props) => {
  const [contact, setContact] = useState(props.contact);
  const [errorArray, setErrorArray] = useState([]);
  const [canSubmit, setCanSubmit] = useState(false);
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");

  useEffect(() => {
    if (!captchaLoaded) {
      loadCaptchaEnginge(6);
      setCaptchaLoaded(true);
    }
  }, [captchaLoaded]);

  useEffect(() => {
    const errorArrayIsValid = errorArray.length === 0;
    const contactIsValid =
      Object.keys(contact).filter((prop) => {
        return contact[prop].length === 0;
      }).length === 0;

    setCanSubmit(
      errorArrayIsValid && contactIsValid && validateCaptcha(captchaValue, false)
    );
  }, [contact, errorArray, captchaValue]);

  const checkSomeValue = (name) => {
    if (contact[name].length === 0) {
      if (!errorArray.includes(name)) {
        setErrorArray([...errorArray, name]);
      }
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== name;
        })
      );
    }
  };

  const checkPhoneNumber = () => {
    if (/^[0-9]{10}$/gm.test(contact.phoneNumber)) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "phoneNumber";
        })
      );
    } else {
      if (!errorArray.includes("phoneNumber")) {
        setErrorArray([...errorArray, "phoneNumber"]);
      }
    }
  };

  const checkEmail = () => {
    if (/(@)(.+)$/gm.test(contact.email)) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "email";
        })
      );
    } else {
      setErrorArray([...errorArray, "email"]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (canSubmit) {
      props.setContact(contact);
      props.setPageNumber(3);
    }
  };

  return (
    <section className="registrationStep registrationStep2">
      <h2>Now we need at least one company contact to start.</h2>
      <p>
        Don't worry, we can add more later. Please fill out the information
        below about yourself, or the most appropriate business contact.
      </p>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <ul>
          <li>
            <label htmlFor="name">Contact name:</label>
            <input
              type="text"
              name="name"
              placeholder="Dwight Shrute"
              value={contact.name}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e) => {
                checkSomeValue(e.target.name);
              }}
            />
            {errorArray.includes("name") ? (
              <p className="error">Name must have value</p>
            ) : null}
          </li>
          <li>
            <label htmlFor="phoneNumber">Contact phone number:</label>
            <input
              type="number"
              min="0"
              name="phoneNumber"
              placeholder="123456789"
              value={contact.phoneNumber}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={() => {
                checkPhoneNumber();
              }}
            />
            {errorArray.includes("phoneNumber") ? (
              <p className="error">
                Invalid phone number. Please enter your 10 digit phone number.
                Example: 1234567890
              </p>
            ) : null}
          </li>
          <li>
            <label htmlFor="email">Contact email:</label>
            <input
              type="text"
              name="email"
              placeholder="dshrute@dundermifflin.com"
              value={contact.email}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={() => {
                checkEmail();
              }}
            />
            {errorArray.includes("email") ? (
              <p className="error">Invalid email</p>
            ) : null}
          </li>
        </ul>
        <div class="captcha">
          <LoadCanvasTemplate />
          <input
            name="captcha"
            value={captchaValue}
            onChange={(e) => setCaptchaValue(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className={`submitButton ${canSubmit ? "" : "inactive"}`}
        >
          Submit √
        </button>
      </form>
      <button
        onClick={() => {
          props.setPageNumber(1);
        }}
      >
        ← Go back
      </button>
    </section>
  );
};

export default RegistrationStep2;
