import React, { useContext } from "react";
import { withRouter } from "react-router-dom";

import CartContext from "../Contexts/CartContext.js";
import { updateItem, removeItem, getCart } from "../Assets/CartHandler.js";
import NoImage from "../Assets/NoImage.js";

import "../Styles/ShoppingCart.scss";

const ShoppingCart = (props) => {
  const { cart, setCart } = useContext(CartContext);

  const handleChange = (e, product) => {
    e.preventDefault();
    if (/^[+]?\d+(\d+)?$/gm.test(e.target.value) || e.target.value === "") {
      updateItem(product, e.target.value);
      setCart(getCart());
    }
  };

  const handleDelete = (product) => {
    removeItem(product);
    setCart(getCart());
  };

  const cartTotal = () => {
    let total = 0;

    cart.forEach((line) => {
      total += parseInt(line.quantity) * line.product.standardCost;
    });

    return total.toFixed(2);
  };

  return (
    <section className="shoppingCart">
      <h2>Shopping cart contents</h2>
      {cart && cart.length > 0 ? (
        <>
          <table className="shoppingCartContents">
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Product ID</th>
                <th>Total Price</th>
                <th>Unit Price</th>
                <th>Quantity</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((line) => {
                const product = line.product;
                const quantity = line.quantity;
                return (
                  <tr key={product.key}>
                    <td>
                      <img
                        src={
                          product.imageAddress
                            ? product.imageAddress
                            : NoImage()
                        }
                        alt="product"
                        onClick={() => {
                          props.history.push(`/product/${product.key}`);
                        }}
                      />
                    </td>
                    <td>{product.description}</td>
                    <td>{product.productId}</td>
                    <td>
                      {(
                        Math.round(
                          parseInt(quantity) * product.standardCost * 100
                        ) / 100
                      ).toFixed(2)}
                    </td>
                    <td>{product.standardCost}</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        value={quantity}
                        onChange={(e) => {
                          handleChange(e, product);
                        }}
                      />
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          handleDelete(product);
                        }}
                      >
                        ❌
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="cartTotal">
            <h3>
              Cart subtotal:{" $"}
              {cartTotal()}
            </h3>
            <button
              onClick={() => {
                props.history.push("/checkout");
              }}
            >
              Checkout
            </button>
          </div>
        </>
      ) : (
        <p className="empty">Nothing to see here</p>
      )}
    </section>
  );
};

export default withRouter(ShoppingCart);
