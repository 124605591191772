import React, { useState, useContext, useEffect } from "react";

import UserContext from "../../Contexts/UserContext.js";
import { authUpdateCustomer } from "../../Adapters/CustomerAdapters.js";
import StatesList from "../../Assets/StatesList.js";

import "../../Styles/AccountPage/Account.scss";

const Account = (props) => {
  const { user, setUser } = useContext(UserContext);
  const [errorArray, setErrorArray] = useState([]);
  const [accountValues, setAccountValues] = useState({});

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      setAccountValues({ ...user.user });
    }
  }, [user]);

  const handleAccountChange = (e) => {
    e.preventDefault();
    setAccountValues({ ...accountValues, [e.target.name]: e.target.value });
  };

  const checkPhoneNumber = (key) => {
    if (/^[0-9]{10}$/gm.test(accountValues[key])) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== key;
        })
      );
    } else {
      if (!errorArray.includes(key)) {
        setErrorArray([...errorArray, key]);
      }
    }
  };

  const checkZipcode = () => {
    if (accountValues.zipCode.length !== 5) {
      setErrorArray([...errorArray, "zipCode"]);
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "zipCode";
        })
      );
    }
  };

  const checkSomeValue = (name) => {
    if (accountValues[name].length === 0) {
      if (!errorArray.includes(name)) {
        setErrorArray([...errorArray, name]);
      }
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== name;
        })
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (errorArray.length > 0) {
      return;
    } else {
      accountValues.key = parseInt(accountValues.key);
      accountValues.zipCode = parseInt(accountValues.zipCode);
      accountValues.phoneNumber = parseInt(accountValues.phoneNumber);
      accountValues.faxNumber = parseInt(accountValues.faxNumber);
      accountValues.customerNumber = parseInt(accountValues.customerNumber);

      authUpdateCustomer(accountValues);

      setUser({ ...user, user: accountValues });
    }
  };

  return (
    <section className="account">
      <h2>Account information</h2>
      {errorArray.length > 0 ? (
        <ul className="error">
          <li>Invalid values in:</li>
          {errorArray.map((cv) => {
            return <li key={cv}>{cv}</li>;
          })}
        </ul>
      ) : null}
      <form
        className="customerInfo"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <label htmlFor="name">Business name:</label>
        <input
          name="name"
          value={accountValues.name || ""}
          placeholder="Dunder Mifflin"
          onChange={(e) => {
            handleAccountChange(e);
          }}
          onBlur={(e) => {
            checkSomeValue(e.target.name);
          }}
        />
        <label htmlFor="address">Business address:</label>
        <input
          name="address"
          value={accountValues.address || ""}
          placeholder="1234 Example Rd"
          onChange={(e) => {
            handleAccountChange(e);
          }}
          onBlur={(e) => {
            checkSomeValue(e.target.name);
          }}
        />
        <label htmlFor="city">Business city:</label>
        <input
          name="city"
          value={accountValues.city || ""}
          placeholder="Milwaukee"
          onChange={(e) => {
            handleAccountChange(e);
          }}
          onBlur={(e) => {
            checkSomeValue(e.target.name);
          }}
        />
        <label htmlFor="state">Business state:</label>
        <select
          defaultValue
          name="state"
          value={accountValues.state || ""}
          onChange={(e) => {
            handleAccountChange(e);
          }}
          onBlur={(e) => {
            checkSomeValue(e.target.name);
          }}
        >
          <option disabled value>
            {" "}
            -- select an option --{" "}
          </option>
          {StatesList.map((state) => {
            return <option key={state}>{state}</option>;
          })}
        </select>
        <label htmlFor="zipCode">Business zipcode:</label>
        <input
          name="zipCode"
          type="number"
          min="0"
          value={accountValues.zipCode || ""}
          placeholder="12345"
          onChange={(e) => {
            handleAccountChange(e);
          }}
          onBlur={() => {
            checkZipcode();
          }}
        />
        <label htmlFor="phoneNumber">Business phone number:</label>
        <input
          name="phoneNumber"
          type="number"
          min="0"
          value={accountValues.phoneNumber || ""}
          placeholder="1234567890"
          onChange={(e) => {
            handleAccountChange(e);
          }}
          onBlur={() => {
            checkPhoneNumber("phoneNumber");
          }}
        />
        <label htmlFor="faxNumber">Business fax number:</label>
        <input
          name="faxNumber"
          type="number"
          min="0"
          value={accountValues.faxNumber || ""}
          placeholder="1234567890"
          onChange={(e) => {
            handleAccountChange(e);
          }}
          onBlur={() => {
            checkPhoneNumber("faxNumber");
          }}
        />
        <label htmlFor="password">Password:</label>
        <input
          name="password"
          type="password"
          value={accountValues.password || ""}
          onChange={(e) => {
            handleAccountChange(e);
          }}
          onBlur={() => {
            checkSomeValue("password");
          }}
        />
        <button
          type="submit"
          className={errorArray.length > 0 ? "disabled" : ""}
        >
          Submit changes
        </button>
      </form>
    </section>
  );
};

export default Account;
