import React, { useState } from "react";

import EditItems from "../Components/AdminPage/EditItems.js";
import EditUsers from "../Components/AdminPage/EditUsers.js";
import EditPurchasingAgents from "../Components/AdminPage/EditPurchasingAgents.js";
import AddPurchasingAgent from "../Components/AdminPage/AddPurchasingAgent.js";
import EditVendors from "../Components/AdminPage/EditVendors.js";
import AddVendor from "../Components/AdminPage/AddVendor.js";
import AllOrders from "../Components/AdminPage/AllOrders.js";
import AddUser from "../Components/AdminPage/AddCustomer.js";
import AddItems from "../Components/AdminPage/AddItems.js";
import BatchAddItems from "../Components/AdminPage/BatchAddItems.js";
import BatchAddCustomers from "../Components/AdminPage/BatchAddCustomers.js";
import BatchAddVendors from "../Components/AdminPage/BatchAddVendors.js";
import ManageCategories from "../Components/AdminPage/ManageCategories.js";

import "../Styles/AdminPage.scss";

const AdminPage = () => {
  const [component, setComponent] = useState(1);

  const componentDict = {
    1: <AddItems />,
    2: <EditItems />,
    3: <AddUser />,
    4: <EditUsers />,
    5: <AllOrders />,
    6: <BatchAddItems />,
    7: <BatchAddCustomers />,
    8: <EditPurchasingAgents />,
    9: <AddPurchasingAgent />,
    10: <BatchAddVendors />,
    11: <EditVendors />,
    12: <AddVendor />,
    13: <ManageCategories />,
  };

  return (
    <section className="adminPage">
      <div className="adminPage-left">
        <ul>
          <li
            onClick={() => {
              setComponent(1);
            }}
          >
            Add Items
          </li>
          <li
            onClick={() => {
              setComponent(2);
            }}
          >
            Edit Items
          </li>
          <li
            onClick={() => {
              setComponent(3);
            }}
          >
            Add Customer
          </li>
          <li
            onClick={() => {
              setComponent(4);
            }}
          >
            Edit Customer
          </li>
          <li
            onClick={() => {
              setComponent(5);
            }}
          >
            All Orders
          </li>
          <li
            onClick={() => {
              setComponent(6);
            }}
          >
            Batch Add Items
          </li>
          <li
            onClick={() => {
              setComponent(7);
            }}
          >
            Batch Add Customers
          </li>
          <li
            onClick={() => {
              setComponent(8);
            }}
          >
            Edit Purchasing Agents
          </li>
          <li
            onClick={() => {
              setComponent(9);
            }}
          >
            Add Purchasing Agent
          </li>
          <li
            onClick={() => {
              setComponent(10);
            }}
          >
            Batch Add Vendors
          </li>
          <li
            onClick={() => {
              setComponent(11);
            }}
          >
            Edit Vendors
          </li>
          <li
            onClick={() => {
              setComponent(12);
            }}
          >
            Add Vendor
          </li>
          <li
            onClick={() => {
              setComponent(13);
            }}
          >
            Manage Categories
          </li>
        </ul>
      </div>
      <div className="adminPage-right">{componentDict[component]}</div>
    </section>
  );
};

export default AdminPage;
