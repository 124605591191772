import React from "react";

import "../Styles/ErrorMessage.scss";

const ErrorMessage = (props) => {
  return (
    <section className="errorMessage">
      <p>Oops! We've encountered error code: {props.error.code}</p>
      <p>Message: {props.error.message}</p>
      <p>
        This may be due to the server, or user input. Please double check that
        you have entered valid information, or call us for help
      </p>
      <a href="tel:+1-602-323-2807">(602) 323-2807</a>
    </section>
  );
};

export default ErrorMessage;
