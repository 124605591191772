import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import NoImage from "../Assets/NoImage.js";
import {
  authGetContacts,
  authGetLocations,
  authGetCustomerCOD,
} from "../Adapters/CustomerAdapters";
import { authPostOrder } from "../Adapters/OrderAdapters.js";
import UserContext from "../Contexts/UserContext.js";
import CartContext from "../Contexts/CartContext.js";
import "../Styles/CheckoutPage.scss";
import { HostedForm } from "react-acceptjs";
import { postToAuthorize } from "../Adapters/DataService.js";

const CheckoutPage = (props) => {
  const authData = {
    apiLoginID: "89Zne2ZSchW",
    clientKey:
      "94K9DyVRdCMCZ2qgsN5XqKyNkZVAve7mjXNkGt5Pp9yt9M8wg7ej45PZ6wgz25RL",
  };

  const { cart, setCart } = useContext(CartContext);
  const { user } = useContext(UserContext);
  const [locations, setLocations] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [customerCOD, setCustomerCOD] = useState(true);
  const [location, setLocation] = useState({});
  const [pickupChecked, setPickupChecked] = useState(false);
  const [contact, setContact] = useState({});
  const [inStoreOnly, setInStoreOnly] = useState(false);

  const handleSubmit = async (event) => {
    const nonce = event?.opaqueData?.dataValue;

    if (!nonce) {
      window.alert("Something went wrong");
    }

    try {
      await postToAuthorize(nonce, parseFloat(cartTotal()));
      await postOrderToApi();
    } catch (err) {
      console.error(err);
      window.alert("Something went wrong");
    }
  };

  useEffect(() => {
    if (user.user) {
      const getdetails = async () => {
        const contactList = await authGetContacts(user.user.key);
        const locationList = await authGetLocations(user.user.key);
        const customerCOD = await authGetCustomerCOD(user.user.key);
        setContacts(contactList.data);
        setLocations(locationList.data);
        setCustomerCOD(customerCOD.data);
      };
      getdetails();
    }
  }, [user]);

  useEffect(() => {
    if (cart && cart.length) {
      const inStoreItems = cart.filter((item) => item.product.inStoreOnly);
      if (inStoreItems.length > 0) {
        setPickupChecked(true);
        setInStoreOnly(true);
        setLocation({ key: 1 });
        localStorage.setItem("location", 1);
      }
    }
  }, [cart]);

  const cartTotal = () => {
    let total = 0;

    cart.forEach((line) => {
      total += parseInt(line.quantity) * line.product.standardCost;
    });

    return total.toFixed(2);
  };

  const postOrderToApi = async () => {
    const orderId = await authPostOrder({
      order: {
        customerKey: parseInt(user.user.key),
        date: Date.now(),
        total: parseFloat(cartTotal()),
        location: location.key || parseInt(localStorage.getItem("location")),
        contact: contact.key || parseInt(localStorage.getItem("contact")),
      },
      products: cart.map((item) => {
        return {
          productKey: item.product.key.toString(),
          quantity: item.quantity,
          price: parseFloat(
            (item.quantity * item.product.standardCost).toFixed(2)
          ),
        };
      }),
    });
    localStorage.removeItem("ctsCart");
    setCart({});
    props.history.push("/historicalorder/" + orderId.data);
  };

  return (
    <div>
      <h1>Checkout 🛒</h1>
      <section className="checkoutForm">
        {
          <>
            <table className="shoppingCartContents">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Product ID</th>
                  <th>Total Price</th>
                  <th>Unit Price</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {cart && cart.length > 0 ? (
                  cart.map((line) => {
                    const product = line.product;
                    const quantity = line.quantity;

                    return (
                      <tr key={product.key}>
                        <td>
                          <img
                            src={
                              product.imageAddress
                                ? product.imageAddress
                                : NoImage()
                            }
                            alt="product"
                            onClick={() => {
                              props.history.push(`/product/${product.key}`);
                            }}
                          />
                        </td>
                        <td>{product.description}</td>
                        <td>{product.productId}</td>
                        <td>
                          {(
                            Math.round(
                              parseInt(quantity) * product.standardCost * 100
                            ) / 100
                          ).toFixed(2)}
                        </td>
                        <td>{product.standardCost}</td>
                        <td>{quantity}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr />
                )}
              </tbody>
            </table>
            <div className="cartTotal">
              <h3>
                Cart subtotal:{" $"}
                {cart && cart.length > 0 ? cartTotal() : ""}
              </h3>
            </div>
          </>
        }
        <form>
          <h2> Contact </h2>
          <label htmlFor="contactInfo"> Select Contact Info :</label>
          <select
            id="contactInfo"
            defaultValue
            onChange={(e) => {
              setContact(
                contacts.filter((con) => {
                  return con.email === e.target.value;
                })[0]
              );
              localStorage.setItem(
                "contact",
                contacts.filter((con) => {
                  return con.email === e.target.value;
                })[0].key
              );
            }}
          >
            <option disabled value>
              {" "}
              -- select an option --{" "}
            </option>
            {contacts.map((contact) => (
              <option key={contact.key}>{contact.email}</option>
            ))}
          </select>
        </form>

        <form>
          <h2> Delivery Location </h2>
          <label htmlFor="locationInfo"> Select Delivery Location:</label>
          <select
            id="locationInfo"
            defaultValue
            disabled={pickupChecked ? "disabled" : ""}
            onChange={(e) => {
              setLocation(
                locations.filter((loc) => {
                  return loc.address === e.target.value;
                })[0]
              );
              localStorage.setItem(
                "location",
                locations.filter((loc) => {
                  return loc.address === e.target.value;
                })[0].key
              );
            }}
          >
            <option disabled value>
              {" "}
              -- select an option --{" "}
            </option>
            {locations.map((location) => (
              <option key={location.key}>{location.address}</option>
            ))}
          </select>
          <label htmlFor="locationInfoCheck">Pick Up In Store</label>
          <input
            disabled={inStoreOnly}
            type="checkbox"
            name="locationInfoCheck"
            value="pickupInStore"
            onChange={(e) => {
              setPickupChecked(e.target.checked);
              if (e.target.checked) {
                setLocation({ key: 1 });
                localStorage.setItem("location", 1);
              } else {
                setLocation(locations[0]);
              }
            }}
          />
          <p>
            {inStoreOnly
              ? "Some item(s) in your cart are available only for in store pickup, so the whole order must be picked up."
              : ""}
          </p>
        </form>
        <div
          className={
            location && location.key && contact && contact.key
              ? "paymentInfo"
              : "paymentInfo invisible"
          }
        >
          <h2> Payment Information</h2>
          <p>
            This may take a moment, please only click the button once. If there
            is a problem with your order, you will be alerted. Otherwise, please
            wait to be redirected{" "}
          </p>
          {!customerCOD ? (
            <div className="putOnAcct">
              <button
                onClick={async (e) => {
                  e.preventDefault();
                  try {
                    await postOrderToApi();
                  } catch (err) {
                    console.error(err);
                    window.alert("Error processing order");
                  }
                }}
              >
                Put on account
              </button>
            </div>
          ) : (
            ""
          )}
          <HostedForm
            authData={authData}
            onSubmit={handleSubmit}
            buttonText="Pay with card"
            environment="PRODUCTION"
          />
        </div>
      </section>
    </div>
  );
};

export default withRouter(CheckoutPage);
