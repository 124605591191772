import CircularSaw from "../Assets/no_image_circular_saw.jpg";
import Drill from "../Assets/no_image_drill.jpg";
import Grinder from "../Assets/no_image_grinder.jpg";
import Sander from "../Assets/no_image_sander.jpg";
import Sawblade from "../Assets/no_image_sawblade.jpg";

const ImageDictionary = {
  1: CircularSaw,
  2: Drill,
  3: Grinder,
  4: Sander,
  5: Sawblade,
};

const NoImage = () => {
  const index = Math.floor(Math.random() * (6 - 1) + 1);
  return ImageDictionary[index];
};

export default NoImage;
