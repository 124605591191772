export class ErrorStatus {
  constructor(status = false, code = "", message = "") {
    this.status = status;
    this.code = code;
    this.message = message;
  }
}

export class CustomerRegistration {
  constructor(
    customerNumber = "",
    phoneNumber = "",
    name = "",
    city = "",
    state = "",
    zipCode = "",
    address = "",
    faxNumber = "",
    password = "",
    confirmPassword = ""
  ) {
    this.customerNumber = customerNumber;
    this.phoneNumber = phoneNumber;
    this.name = name;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.address = address;
    this.faxNumber = faxNumber;
    this.password = password;
    this.confirmPassword = confirmPassword;
  }
}

export class CustomerContact {
  constructor(name = "", email = "", phoneNumber = "") {
    this.name = name;
    this.email = email;
    this.phoneNumber = phoneNumber;
  }
}

export class CustomerLocation {
  constructor(city = "", state = "", zipCode = "", address = "") {
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.address = address;
  }
}
