import { get, post, put, del } from "./DataService.js";

export const getAllVendors = () => {
  return get("/api/vendors");
};

export const authGetVendorById = (key) => {
  return get("/api/authvendors/" + key);
};

export const authBatchInsertVendors = (doc, key) => {
  const formData = new FormData();
  formData.append("doc", doc);
  formData.append("agentKey", key);

  return post("/api/authvendors/batch", formData);
};

export const authUpdateVendor = (vendor) => {
  return put("/api/authvendors", vendor);
};

export const authInsertVendor = (vendor) => {
  return post("/api/authvendors", vendor);
};

export const authDeleteVendor = (id) => {
  return del("/api/authvendors/" + id);
};
