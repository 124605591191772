import { get, post, put, del } from "./DataService.js";

export const authGetAllPurchasingAgents = () => {
  return get("/api/authpurchasingagents");
};

export const authUpdatePurchasingAgent = (agent) => {
  return put("/api/authpurchasingagents", agent);
};

export const authInsertPurchasingAgent = (agent) => {
  return post("/api/authpurchasingagents", agent);
};

export const authDeletePurchasingAgent = (id) => {
  return del("/api/authpurchasingagents/" + id);
};
