import CryptoJS from "crypto-js";
const cipherCode = "f6dd41dc-911b-11eb-a8b3-0242ac130003";

export const encodeString = (text) => {
  return CryptoJS.AES.encrypt(text, cipherCode).toString();
};

export const decodeString = (encrypted) => {
  const bytes = CryptoJS.AES.decrypt(encrypted, cipherCode);
  return bytes.toString(CryptoJS.enc.Utf8);
};
