import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getProductByKey } from "../Adapters/ProductAdapters.js";
import LoadingSpinner from "../Components/LoadingSpinner.js";
import NoImage from "../Assets/NoImage.js";
import ProductDetailPricing from "../Components/ProductDetailPricing.js";
import Favorite from "../Components/Favorite.js";

import "../Styles/ProductDetailPage.scss";

const ProductDetailPage = (props) => {
  const [product, setProduct] = useState({});

  useEffect(() => {
    const getdetails = async () => {
      const productKey = props.match.params.productKey;
      const product = await getProductByKey(productKey);

      setProduct(product.data);
    };
    getdetails();
  }, []);
  return (
    <>
      {Object.keys(product).length > 0 ? (
        <section className="productDetailPage">
          <span>
            <Link to="/">Home</Link> {"→"}{" "}
            <Link to={`/product/categories/${product.category.key}`}>
              {product.category.name}
            </Link>{" "}
            {"→"} {product.description}
          </span>
          <div>
            <div className="productImage">
              <img
                src={product.imageAddress ? product.imageAddress : NoImage()}
                alt="Product"
              />
              <p>
                {product.imageAddress
                  ? "note: product may differ slightly from image"
                  : ""}
              </p>
              <p>{product.inStoreOnly ? "available only in store" : ""}</p>
            </div>
            <div className="productDetailPage-textContent">
              <h3>{product.vendor.name}</h3>
              <h2>{product.description}</h2>
              <p>
                Item ID: {product.productId} || {product.category.name}
              </p>
              {product.countryOfOrigin ? (
                <p>Country of origin: {product.countryOfOrigin}</p>
              ) : (
                ""
              )}
              <Favorite product={product} />
              <ProductDetailPricing product={product} />
            </div>
          </div>
        </section>
      ) : (
        <LoadingSpinner height="300px" />
      )}
    </>
  );
};

export default ProductDetailPage;
