import React, { useState } from "react";

import Account from "../Components/AccountPage/Account.js";
import Contacts from "../Components/AccountPage/Contacts.js";
import FavoritesList from "../Components/AccountPage/FavoritesList.js";
import OrderHistory from "../Components/AccountPage/OrderHistory.js";
import Locations from "../Components/AccountPage/Locations.js";

import "../Styles/AccountPage.scss";

const AccountPage = (props) => {
  const [component, setComponent] = useState(props.component || 1);

  const componentDict = {
    1: <Account />,
    2: <Contacts />,
    3: <Locations />,
    4: <FavoritesList />,
    5: <OrderHistory />,
  };

  return (
    <section className="accountPage">
      <div className="accountPage-left">
        <ul>
          <li
            onClick={() => {
              setComponent(1);
            }}
          >
            Account
          </li>
          <li
            onClick={() => {
              setComponent(2);
            }}
          >
            Contacts
          </li>
          <li
            onClick={() => {
              setComponent(3);
            }}
          >
            Locations
          </li>
          <li
            onClick={() => {
              setComponent(4);
            }}
          >
            Favorites
          </li>
          <li
            onClick={() => {
              setComponent(5);
            }}
          >
            Order history
          </li>
        </ul>
      </div>
      <div className="accountPage-right">{componentDict[component]}</div>
    </section>
  );
};

export default AccountPage;
