import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { authGetOrdersForCustomer } from "../../Adapters/OrderAdapters.js";

import "../../Styles/AccountPage/OrderHistory.scss";

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const getorders = async () => {
      const orderList = await authGetOrdersForCustomer();
      setOrders(orderList.data);
    };
    getorders();
  }, []);

  const getDate = (orderDate) => {
    const date = new Date(0);
    date.setSeconds(orderDate);
    return date.toLocaleDateString();
  };

  return (
    <section className="orderHistory">
      <h2>Order history</h2>
      <table>
        <thead>
          <tr>
            <th>Order Number</th>
            <th>Order date</th>
            <th>Total price</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => {
            return (
              <tr key={order.key}>
                <td>
                  <Link to={`/order/${order.key}`}>{order.key}</Link>
                </td>
                <td>{getDate(order.date)}</td>
                <td>{order.total}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};

export default OrderHistory;
