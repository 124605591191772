import { encodeString, decodeString } from "./Encryption.js";

export const addItemToCart = (product, quantity) => {
  const shoppingCart = getCart();

  if (shoppingCart) {
    const existingItem = shoppingCart.filter((entry) => {
      return entry.product.key === product.key;
    })[0];

    if (existingItem) {
      shoppingCart[shoppingCart.indexOf(existingItem)].quantity += quantity;
    } else {
      shoppingCart.push({ product, quantity });
    }

    saveCart(shoppingCart);
  } else {
    const newCart = [];
    newCart.push({ product, quantity });

    saveCart(newCart);
  }
};

export const updateItem = (product, quantity) => {
  const shoppingCart = getCart();

  const existingItem = shoppingCart.filter((entry) => {
    return entry.product.key === product.key;
  })[0];

  shoppingCart[shoppingCart.indexOf(existingItem)].quantity = quantity;

  saveCart(shoppingCart);
  return shoppingCart;
};

export const removeItem = (product) => {
  const shoppingCart = getCart();

  const newCart = shoppingCart.filter((entry) => {
    return entry.product.key !== product.key;
  });

  saveCart(newCart);
};

export const getCart = () => {
  const encodedCart = localStorage.getItem("ctsCart");

  if (encodedCart) {
    const cart = decodeString(encodedCart);
    return JSON.parse(cart);
  } else {
    return null;
  }
};

export const saveCart = (cart) => {
  const encodedCart = encodeString(JSON.stringify(cart));
  localStorage.setItem("ctsCart", encodedCart);
};
