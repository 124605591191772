import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getAllCategories } from "../Adapters/ProductAdapters.js";
import LoadingSpinner from "../Components/LoadingSpinner.js";
import AlphabetDictionary from "../Assets/AlphabetDictionary.js";

import "../Styles/AllCategories.scss";

const AllCategories = () => {
  const [categories, setCategories] = useState({});

  useEffect(() => {
    const getcats = async () => {
      const categoryData = await getAllCategories();
      const alphaDict = JSON.parse(JSON.stringify(AlphabetDictionary));

      categoryData.data.forEach((cat) => {
        alphaDict[cat.name[0].toUpperCase()].push(cat);
      });

      setCategories(alphaDict);
    };
    getcats();
  }, []);

  return (
    <>
      {categories && Object.keys(categories).length > 0 ? (
        <section className="allCategories">
          <h2>All product categories</h2>
          <div className="allCategories-catContent">
            {Object.keys(categories).map((key) => {
              if (categories[key].length > 0) {
                return (
                  <div key={key}>
                    <h3>{key}</h3>
                    {categories[key].map((cat) => {
                      return (
                        <Link
                          key={cat.key * Math.random()}
                          to={`/product/categories/${cat.key}`}
                        >
                          {cat.name}
                        </Link>
                      );
                    })}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </section>
      ) : (
        <LoadingSpinner height="300px" />
      )}
    </>
  );
};

export default AllCategories;
