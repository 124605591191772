import React, { useState } from "react";

import EditUsersSearch from "./EditUsersSearch.js";
import EditUsersForm from "./EditUsersForm.js";

import "../../Styles/AdminPage/EditUsers.scss";

const EditUsers = () => {
  const [user, setUser] = useState({});

  return user && Object.keys(user).length > 0 ? (
    <EditUsersForm
      setUser={(prod) => {
        setUser(prod);
      }}
      user={user}
    />
  ) : (
    <EditUsersSearch
      setUser={(user) => {
        setUser(user);
      }}
    />
  );
};

export default EditUsers;
