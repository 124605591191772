import React from "react";

import HammerSpin from "../Assets/hammer-spin.gif";

import "../Styles/LoadingSpinner.scss";

const LoadingSpinner = ({ height }) => {
  const styles = {
    height: height,
  };
  return (
    <section className="loadingSpinner" style={styles}>
      <img src={HammerSpin} alt="loading..." />
    </section>
  );
};

export default LoadingSpinner;
