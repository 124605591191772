import { encodeString } from "./Encryption.js";

const ValidateToken = () => {
  const token = localStorage.getItem("ctsToken");

  if (!token) {
    return false;
  }

  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const tokenInfo = JSON.parse(jsonPayload);
  const dateNow = parseInt(new Date().getTime().toString().slice(0, -3));

  const validToken = dateNow < tokenInfo.exp && dateNow > tokenInfo.nbf;

  if (validToken) {
    const userInfo = {
      user: {
        key: tokenInfo.Key,
        customerNumber: tokenInfo.CustomerNumber,
        name: tokenInfo.Name,
        phoneNumber: tokenInfo.PhoneNumber,
        address: tokenInfo.Address,
        zipCode: tokenInfo.ZipCode,
        city: tokenInfo.City,
        state: tokenInfo.State,
        faxNumber: tokenInfo.FaxNumber,
        isAdmin: tokenInfo.PricingTier === "42069",
      },
      token: token,
    };

    localStorage.setItem("ctsUser", encodeString(JSON.stringify(userInfo)));
  }

  return validToken;
};

export default ValidateToken;
