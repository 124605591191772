import React from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import NoImage from "../Assets/NoImage.js";

import "../Styles/ProductListView.scss";

const ProductListView = (props) => {
  return (
    <AnimatePresence>
      <motion.section
        className="productListView"
        initial={{ position: "relative", left: 50 }}
        animate={{ position: "relative", left: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.7, type: "spring", bounce: 0.5 }}
      >
        <Link to={`/product/${props.product.key}`}>
          <img
            src={
              props.product.imageAddress
                ? props.product.imageAddress
                : NoImage()
            }
            alt="Product"
          />
          <h3>
            {props.product.description ? props.product.description : "N/A"}
          </h3>
          <p>
            Item ID: {props.product.productId ? props.product.productId : "N/A"}
          </p>
          {props.product.featured ? <h4>${props.product.standardCost}</h4> : ""}
        </Link>
      </motion.section>
    </AnimatePresence>
  );
};

export default ProductListView;
