import React, { useState } from "react";

import EditVendorsSearch from "./EditVendorsSearch.js";
import EditVendorsForm from "./EditVendorsForm.js";

import "../../Styles/AdminPage/EditPurchasingAgents.scss";

const EditVendors = () => {
  const [vendor, setVendor] = useState({});

  return vendor && Object.keys(vendor).length > 0 ? (
    <EditVendorsForm
      setVendor={(obj) => {
        setVendor(obj);
      }}
      vendor={vendor}
    />
  ) : (
    <EditVendorsSearch
      setVendor={(vendor) => {
        setVendor(vendor);
      }}
    />
  );
};

export default EditVendors;
