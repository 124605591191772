import React from "react";
import { Link } from "react-router-dom";

import "../../Styles/HeaderFooter/Logo.scss";

import LogoImg from "../../Assets/cts_banner.svg";

const Logo = () => {
  return (
    <Link to="/" className="logo">
      <img src={LogoImg} alt="Construction Tool And Supply Logo" />
    </Link>
  );
};

export default Logo;
