import { post, get } from "./DataService.js";

export const authPostOrder = (order) => {
  return post("/api/authorders", order);
};

export const authGetOrdersForCustomer = () => {
  return get("/api/authorders");
};

export const authGetOrderByKey = (orderKey) => {
  return get(`/api/authorders/bykey/${orderKey}`);
};

export const authGetAllOrders = () => {
  return get(`/api/authorders/all`);
};

export const authPostAudit = (audit) => {
    return post("/api/authorders/audit", audit);
};