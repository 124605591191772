import React from "react";

import Map from "../../Assets/streetMap.png";

import "../../Styles/HeaderFooter/Footer.scss";

const Footer = () => {
    return (
        <section className="footer">
            <div>
                <h2>
                    The only way to complete a serious project is to have serious
                    equipment
                </h2>
                <div>
                    <div>
                        <h2>Phoenix, AZ</h2>
                        <p><a href="https://goo.gl/maps/nLiyK955vpGUCMRCA" target="_blank" rel="noreferrer">5213 S. 30th Street, Suite C300 Phoenix, AZ 85040</a></p>
                    </div>
                    <div>
                        <h2>Contact us</h2>
                        <p className="phone">
                            <span>Phone: </span>
                            <a href="tel:6023232807">
                                (602) 323-2807
                            </a>
                        </p>
                        <span>Monday - Friday</span>
                        <p>6:00 AM - 4:30 PM</p>
                    </div>
                </div>
            </div>
            <a href="https://goo.gl/maps/nLiyK955vpGUCMRCA" target="_blank" rel="noreferrer"><img src={Map} alt="Street map" /></a>
        </section>
    );
};

export default Footer;
