import React, { useState } from "react";

import { getUsersBySearchTerm } from "../../Adapters/CustomerAdapters.js";

const EditUsersSearch = (props) => {
  const [users, setUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  const itemSearch = async (e) => {
    e.preventDefault();
    const items = await getUsersBySearchTerm(searchValue);
    setUsers(items.data);
  };

  return (
    <section className="editUsers">
      <div className="usersSearch">
        <form
          onSubmit={(e) => {
            itemSearch(e);
          }}
        >
          <input
            type="text"
            placeholder="Search users here"
            onChange={(e) => {
              handleChange(e);
            }}
            value={searchValue}
          />
          <button type="submit">{"→"}</button>
        </form>
      </div>
      <div className="usersList">
        <table className="userTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Customer Number</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return (
                <tr
                  key={user.key}
                  className="tableList"
                  onClick={() => {
                    props.setUser(user);
                  }}
                >
                  <td>{user.name}</td>
                  <td>{user.customerNumber}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default EditUsersSearch;
