import Axios from "axios";

const createAxiosInstance = () => {
  const token = localStorage.getItem("ctsToken");

  return Axios.create({
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const get = (url) => {
  const axios = createAxiosInstance();
  return axios.get(url);
};

export const post = (url, data) => {
  const axios = createAxiosInstance();
  return axios.post(url, data);
};

export const put = (url, data) => {
  const axios = createAxiosInstance();
  return axios.put(url, data);
};

export const del = (url) => {
  const axios = createAxiosInstance();
  return axios.delete(url);
};

export const postToAuthorize = (nonce, amount) => {
  return Axios({
    method: "post",
    url: "https://api.authorize.net/xml/v1/request.api",
    data: {
      createTransactionRequest: {
        merchantAuthentication: {
          name: "89Zne2ZSchW",
          transactionKey: "7RDX3VZy97pp76fC",
        },
        transactionRequest: {
          transactionType: "authCaptureTransaction",
          amount: "" + amount,
          payment: {
            opaqueData: {
              dataDescriptor: "COMMON.ACCEPT.INAPP.PAYMENT",
              dataValue: nonce,
            },
          },
        },
      },
    },
  });
};
