import React, { useContext, useState, useEffect } from "react";

import Toolbox from "../Assets/toolbox.svg";
import UserContext from "../Contexts/UserContext.js";
import {
  authAddProductToFavorites,
  authRemoveProductFromFavorites,
} from "../Adapters/ProductAdapters.js";

import "../Styles/Favorite.scss";

const Favorite = (props) => {
  const [product, setProduct] = useState({});
  const { user } = useContext(UserContext);

  useEffect(() => {
    setProduct(props.product);
  }, [props.product]);

  const addProductToFavorites = async () => {
    await authAddProductToFavorites({
      customerKey: parseInt(user.user.key),
      productKey: product.key,
    });
    setProduct({ ...product, favorite: true });
  };

  const removeProductFromFavorites = async () => {
    await authRemoveProductFromFavorites({
      key: product.favoriteKey,
      customerKey: parseInt(user.user.key),
      productKey: product.key,
    });
    setProduct({ ...product, favorite: false });
  };

  return product && user && user.token ? (
    <section className="favorite">
      <button
        onClick={() => {
          product.favorite
            ? removeProductFromFavorites()
            : addProductToFavorites();
        }}
      >
        {product.favorite ? "Remove favorite" : "Add favorite"}{" "}
        <img
          src={Toolbox}
          alt="add/remove favorite"
          className={product.favorite ? "favorite-icon" : "notfavorite-icon"}
        />
      </button>
    </section>
  ) : (
    ""
  );
};

export default Favorite;
