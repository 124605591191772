import React, { useState, useEffect } from "react";

import {
  authUpdateVendor,
  authDeleteVendor,
  authGetVendorById,
} from "../../Adapters/VendorAdapters";
import StatesList from "../../Assets/StatesList.js";

const EditVendorForm = (props) => {
  const [vendor, setVendor] = useState(props.vendor);
  const [errorArray, setErrorArray] = useState([]);

  useEffect(() => {
    const getvendor = async () => {
      const vend = await authGetVendorById(props.vendor.key);
      setVendor(vend.data);
    };
    getvendor();
  }, []);

  const submittedAlert = () => {
    window.alert("Submitted succesfully");
  };

  const goBack = () => {
    props.setVendor({});
  };

  const updateVendor = async (e) => {
    e.preventDefault();
    await authUpdateVendor({
      ...vendor,
      zipCode: parseInt(vendor.zipCode),
      distMethodKey: parseInt(vendor.distMethodKey),
      purchasingAgentKey: parseInt(vendor.purchasingAgentKey),
    });
    submittedAlert();
    goBack();
  };

  const deleteVendor = async () => {
    const confirm = window.confirm(
      "Are you sure you want to delete this vendor?"
    );
    if (confirm) {
      try {
        await authDeleteVendor(props.vendor.key);
      } catch (err) {
        console.error(err);
        window.alert("Error deleting this vendor.");
      } finally {
        goBack();
      }
    }
  };

  const checkSomeValue = (key) => {
    if (vendor[key].length === 0) {
      if (!errorArray.includes(key)) {
        setErrorArray([...errorArray, key]);
      }
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== key;
        })
      );
    }
  };

  const checkPhoneNumber = (key) => {
    if (/^[0-9]{10}$/gm.test(vendor[key])) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== key;
        })
      );
    } else {
      if (!errorArray.includes(key)) {
        setErrorArray([...errorArray, key]);
      }
    }
  };

  return (
    <section className="editVendorsForm">
      <>
        <ul className={errorArray.length > 0 ? "errors" : "hidden"}>
          {errorArray.map((err) => {
            return <li>{err}</li>;
          })}
        </ul>
        <button
          onClick={() => {
            goBack();
          }}
        >
          ←
        </button>
        <form
          onSubmit={(e) => {
            updateVendor(e);
          }}
        >
          <h2>Vendor Info</h2>
          <label htmlFor="name">Name:</label>
          <input
            value={vendor.name}
            name="name"
            onChange={(e) => {
              setVendor({ ...vendor, name: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("name");
            }}
          />

          <label htmlFor="id">Id:</label>
          <input
            value={vendor.id}
            name="id"
            onChange={(e) => {
              setVendor({ ...vendor, id: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("id");
            }}
          />

          <label htmlFor="address">Address:</label>
          <input
            value={vendor.address}
            name="address"
            onChange={(e) => {
              setVendor({ ...vendor, address: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("address");
            }}
          />

          <label htmlFor="state">State:</label>
          <select
            defaultValue
            value={vendor.state}
            name="state"
            onChange={(e) => {
              setVendor({
                ...vendor,
                state: e.target.value,
              });
            }}
          >
            <option disabled value>
              {" "}
              -- select an option --{" "}
            </option>
            {StatesList.map((state) => {
              return <option key={state}>{state}</option>;
            })}
          </select>

          <label htmlFor="city">City:</label>
          <input
            value={vendor.city}
            name="city"
            onChange={(e) => {
              setVendor({ ...vendor, city: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("city");
            }}
          />

          <label htmlFor="zipCode">Zip code:</label>
          <input
            value={vendor.zipCode}
            name="zipCode"
            onChange={(e) => {
              setVendor({ ...vendor, zipCode: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("zipCode");
            }}
          />

          <label htmlFor="federalTaxId">Federal Tax Id:</label>
          <input
            value={vendor.federalTaxId}
            name="federalTaxId"
            onChange={(e) => {
              setVendor({ ...vendor, federalTaxId: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("federalTaxId");
            }}
          />

          <label htmlFor="accountNumber">Account Number:</label>
          <input
            value={vendor.accountNumber}
            name="accountNumber"
            onChange={(e) => {
              setVendor({ ...vendor, accountNumber: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("accountNumber");
            }}
          />

          <label htmlFor="distMethodKey">Dist Method Key:</label>
          <input
            value={vendor.distMethodKey}
            name="distMethodKey"
            onChange={(e) => {
              setVendor({ ...vendor, distMethodKey: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("distMethodKey");
            }}
          />

          <label htmlFor="terms">Terms:</label>
          <input
            value={vendor.terms}
            name="terms"
            onChange={(e) => {
              setVendor({ ...vendor, terms: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("terms");
            }}
          />

          <label htmlFor="ourAccountNumber">Our Account Number:</label>
          <input
            value={vendor.ourAccountNumber}
            name="ourAccountNumber"
            onChange={(e) => {
              setVendor({ ...vendor, ourAccountNumber: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("ourAccountNumber");
            }}
          />

          <label htmlFor="contactName">Contact name:</label>
          <input
            value={vendor.contactName}
            name="contactName"
            onChange={(e) => {
              setVendor({ ...vendor, contactName: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("contactName");
            }}
          />

          <label htmlFor="phoneNumber">Phone number:</label>
          <input
            value={vendor.phoneNumber}
            name="phoneNumber"
            onChange={(e) => {
              setVendor({ ...vendor, phoneNumber: e.target.value });
            }}
            onBlur={(e) => {
              checkPhoneNumber("phoneNumber");
            }}
          />

          <label htmlFor="faxNumber">Fax number:</label>
          <input
            value={vendor.faxNumber}
            name="faxNumber"
            onChange={(e) => {
              setVendor({ ...vendor, faxNumber: e.target.value });
            }}
            onBlur={(e) => {
              checkPhoneNumber("faxNumber");
            }}
          />

          <button
            className={errorArray.length > 0 ? "disabled" : ""}
            type="submit"
          >
            Submit changes
          </button>
        </form>

        <h3>DANGER ZONE</h3>
        <button
          onClick={() => {
            deleteVendor();
          }}
        >
          Delete vendor
        </button>
      </>
    </section>
  );
};

export default EditVendorForm;
