import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";

import { getAllCategories } from "../../Adapters/ProductAdapters.js";
import CategoriesModal from "./CategoriesModal.js";

import "../../Styles/HeaderFooter/Navigation.scss";

const Navigation = (props) => {
  const [categories, setCategories] = useState([]);
  const [mobileShow, setMobileShow] = useState(false);

  useEffect(() => {
    const getcats = async () => {
      const categories = await getAllCategories();
      setCategories(categories.data);
    };
    getcats();
  }, []);

  const handleScroll = () => {
    const pos = window.scrollY;
    const halfPoint = window.scrollMaxY / 2;

    if (pos < halfPoint) {
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <section className="navigation">
        <div
          className="hamburger"
          onClick={() => {
            setMobileShow(!mobileShow);
          }}
        >
          ☰
        </div>
        <div
          className={`navigation-left ${
            mobileShow ? "mobile-show" : "mobile-hide"
          }`}
        >
          <Link to="/">Home</Link>
          <span
            id="categories"
            onClick={() => {
              props.history.push("/allCategories");
            }}
          >
            Shop Products {"↓"}
            <span className="modal">
              <CategoriesModal categories={categories} />
            </span>
          </span>
          <Link to="/onsale">Clearance Products</Link>
        </div>
        <div className="navigation-right">
          <p>Delivering the tools to build Arizona since 1983.</p>
        </div>
      </section>
      <div id="scroll" onClick={handleScroll}>
        {"⇅"}
      </div>
    </>
  );
};

export default withRouter(Navigation);
