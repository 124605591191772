import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";

import "./App.scss";

import Header from "./Components/HeaderFooter/Header.js";
import Navigation from "./Components/HeaderFooter/Navigation.js";
import Footer from "./Components/HeaderFooter/Footer.js";

import HomePage from "./Views/HomePage.js";
import ProductDetailPage from "./Views/ProductDetailPage.js";
import AllCategories from "./Views/AllCategories";
import CategoryProductPage from "./Views/CategoryProductPage.js";
import SearchTermPage from "./Views/SearchTermPage.js";
import SaleItemsPage from "./Views/SaleItemsPage.js";
import LoginPage from "./Views/LoginPage.js";
import Registration from "./Views/Registration.js";
import ShoppingCart from "./Views/ShoppingCart.js";
import AccountPage from "./Views/AccountPage.js";
import OrderPage from "./Views/OrderPage.js";
import HistoricalOrder from "./Views/HistoricalOrder.js";
import AdminPage from "./Views/AdminPage.js";
import CheckoutPage from "./Views/CheckoutPage.js";

import UserContext from "./Contexts/UserContext.js";
import CartContext from "./Contexts/CartContext.js";

import ValidateToken from "./Assets/ValidateToken.js";
import PrivateRoute from "./Assets/PrivateRoute.js";
import AdminRoute from "./Assets/AdminRoute.js";
import { getCart } from "./Assets/CartHandler.js";
import { decodeString, encodeString } from "./Assets/Encryption.js";

function App() {
  const [user, setUser] = useState({});
  const [cart, setCart] = useState({});

  useEffect(() => {
    const localcart = localStorage.getItem("ctsCart");
    if (localcart) {
      setCart(getCart());
    }
  }, []);

  useEffect(() => {
    const existingToken = localStorage.getItem("ctsToken");
    const existingUser = localStorage.getItem("ctsUser");

    if (user.token && !existingToken) {
      localStorage.setItem("ctsToken", user.token);
      localStorage.setItem("ctsUser", encodeString(JSON.stringify(user.user)));
    } else if (existingToken && existingUser) {
      if (!ValidateToken()) {
        setUser({});
        localStorage.clear();
      } else if (existingUser && Object.keys(user).length === 0) {
        const decodedUser = decodeString(existingUser);
        setUser(JSON.parse(decodedUser));
      }
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <CartContext.Provider value={{ cart, setCart }}>
        <div className="App">
          <Header />
          <Navigation />
          <Route exact path="/" component={HomePage} />
          <Route
            exact
            path="/product/:productKey"
            component={ProductDetailPage}
          />
          <Route path="/allCategories" component={AllCategories} />
          <Route
            path="/product/categories/:categoryKey"
            component={CategoryProductPage}
          />
          <Route
            path="/product/search/:searchTerm"
            component={SearchTermPage}
          />
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={Registration} />
          <Route path="/onsale" component={SaleItemsPage} />

          <PrivateRoute path="/cart">
            <ShoppingCart />
          </PrivateRoute>
          <PrivateRoute path="/account">
            <AccountPage />
          </PrivateRoute>
          <PrivateRoute path="/order/:orderKey">
            <OrderPage />
          </PrivateRoute>
          <PrivateRoute path="/historicalorder/:orderKey">
            <HistoricalOrder />
          </PrivateRoute>
          <PrivateRoute path="/checkout">
            <CheckoutPage />
          </PrivateRoute>

          <AdminRoute path="/admin">
            <AdminPage />
          </AdminRoute>
          <Footer />
        </div>
      </CartContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
