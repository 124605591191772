import { get, post, put, del } from "./DataService.js";

export const getAllProducts = () => {
  return get("/api/products");
};

export const getFeaturedProducts = () => {
  return get("/api/products/featured");
};

export const getAllCategories = () => {
  return get("/api/products/categories");
};

export const getFeaturedCategories = () => {
  return get("/api/products/categories/featured");
};

export const getProductByKey = (productKey) => {
  return get(`/api/products/${productKey}`);
};

export const getProductsByCategory = (categoryKey) => {
  return get(`/api/products/categories/${categoryKey}`);
};

export const getProductsBySearchTerm = (searchTerm) => {
  return get(`/api/products/search/${searchTerm}`);
};

export const getSaleProducts = () => {
  return get("/api/products/onsale");
};

export const authAddProductToFavorites = (product) => {
  return post(`/api/authproducts/favorites`, product);
};

export const authRemoveProductFromFavorites = (product) => {
  return post(`/api/authproducts/favorites/remove`, product);
};

export const authGetFavorites = () => {
  return get(`/api/authproducts/favorites`);
};

export const authGetFullProductDetails = (productKey) => {
  return get(`/api/authproducts/fulldetails/${productKey}`);
};

export const authUpdateProduct = (product) => {
  return put("/api/authproducts", product);
};

export const authDeleteProduct = (productKey) => {
  return del(`/api/authproducts/${productKey}`);
};

export const authUpdateProductPricingInfo = (pricingInfo) => {
  return put("/api/authproducts/pricingInfo", pricingInfo);
};

export const authInsertProduct = (product) => {
  return post("/api/authproducts", product);
};

export const authBatchInsertProduct = (data) => {
  const formData = new FormData();
  formData.append("doc", data.doc);
  formData.append("vendorKey", data.vendorKey);

  return post("/api/authproducts/batch", formData);
};

export const authDeleteCategory = (key) => {
  return del(`/api/authproducts/category/${key}`);
};

export const authUpdateCategory = (category) => {
  return put("/api/authproducts/category", category);
};

export const authInsertCategory = (category) => {
  return post("/api/authproducts/category", category);
};

export const authGetProductsFromOrder = (orderKey) => {
  return get("/api/authproducts/fromorder/" + orderKey);
};
