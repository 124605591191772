import React, { useState, useContext } from "react";
import { Link, withRouter } from "react-router-dom";

import ValidateToken from "../Assets/ValidateToken.js";
import { addItemToCart, getCart } from "../Assets/CartHandler.js";
import CartContext from "../Contexts/CartContext.js";

import "../Styles/ProductDetailPricing.scss";

const ProductDetailPricing = (props) => {
  const [count, setCount] = useState(parseInt(props.product.defaultQuantity));
  const { setCart } = useContext(CartContext);

  const handleChange = (e) => {
    e.preventDefault();
    if (/^[+]?\d+(\d+)?$/gm.test(e.target.value) || e.target.value === "") {
      setCount(Math.floor(e.target.value));
    }
  };

  return (
    <section className="productDetailPricing">
      {ValidateToken() ? (
        <>
          <ul>
            <li>Price: ${props.product.standardCost} each</li>
            <li>Default purchase quantity: {props.product.defaultQuantity}</li>
          </ul>
          <div className="cartControls">
            <ul>
              <li>
                <button
                  onClick={() => {
                    setCount(parseInt(count) - 1);
                  }}
                >
                  ←
                </button>{" "}
                <input
                  type="number"
                  min="0"
                  name="quantity"
                  value={count}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />{" "}
                <button
                  onClick={() => {
                    setCount(parseInt(count) + 1);
                  }}
                >
                  →
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    addItemToCart(props.product, count);
                    setCart(getCart());
                    props.history.push("/cart");
                  }}
                >
                  Add to cart
                </button>
              </li>
            </ul>
          </div>
        </>
      ) : (
        <p>
          <Link to="/login">Sign in</Link> to view pricing
        </p>
      )}
    </section>
  );
};

export default withRouter(ProductDetailPricing);
