import React, { useState, useContext, useEffect } from "react";

import UserContext from "../../Contexts/UserContext.js";
import {
  authGetContacts,
  authDeleteContact,
  authUpdateContact,
  authInsertContact,
} from "../../Adapters/CustomerAdapters.js";
import Contact from "./CustomerContact.js";
import { CustomerContact } from "../../Assets/UtilityClasses.js";

import "../../Styles/AccountPage/Contacts.scss";

const Contacts = (props) => {
  const { user } = useContext(UserContext);
  const [errorArray, setErrorArray] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [formValues, setFormValues] = useState(new CustomerContact());

  const fetchContacts = async () => {
    var contacts = await authGetContacts(props.userKey);
    setContactList(contacts.data);
  };

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      fetchContacts();
    }
  }, [user]);

  const deleteContact = async (contactKey) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this contact?"
    );
    if (confirmation) {
      await authDeleteContact(props.userKey, contactKey);
      await fetchContacts();
    }
  };

  const updateContact = async (contact) => {
    await authUpdateContact(contact);
    await fetchContacts();
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const checkPhoneNumber = () => {
    if (/^[0-9]{10}$/gm.test(formValues.phoneNumber)) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "phoneNumber";
        })
      );
    } else {
      if (!errorArray.includes("phoneNumber")) {
        setErrorArray([...errorArray, "phoneNumber"]);
      }
    }
  };

  const checkSomeValue = (name) => {
    if (formValues[name].length === 0) {
      if (!errorArray.includes(name)) {
        setErrorArray([...errorArray, name]);
      }
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== name;
        })
      );
    }
  };

  const checkEmail = () => {
    if (/(@)(.+)$/gm.test(formValues.email)) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "email";
        })
      );
    } else {
      setErrorArray([...errorArray, "email"]);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (errorArray.length === 0) {
      await authInsertContact({
        name: formValues.name,
        email: formValues.email,
        phoneNumber: parseInt(formValues.phoneNumber),
        customerKey: parseInt(props.userKey),
      });
      await fetchContacts();
      setFormValues(new CustomerContact());
    }
  };

  return (
    <section className="contacts">
      <div className="tableContainer">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Edit</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {contactList.map((contact) => {
              return (
                <Contact
                  key={contact.key}
                  contact={contact}
                  deleteContact={(contactKey) => {
                    deleteContact(contactKey);
                  }}
                  updateContact={(contact) => {
                    updateContact(contact);
                  }}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <h2>New contact</h2>
      {errorArray.length > 0 ? (
        <ul className="error">
          <li>Invalid values in:</li>
          {errorArray.map((cv) => {
            return <li key={cv}>{cv}</li>;
          })}
        </ul>
      ) : null}
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <label htmlFor="name">Name:</label>
        <input
          name="name"
          placeholder="Schmitty Werbenjagermanjensen"
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={() => {
            checkSomeValue("name");
          }}
        />
        <label htmlFor="phoneNumber">Phone number:</label>
        <input
          name="phoneNumber"
          placeholder="1234567890"
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={() => {
            checkPhoneNumber();
          }}
        />
        <label htmlFor="email">Email:</label>
        <input
          name="email"
          placeholder="schmittyw@numberone.com"
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={() => {
            checkEmail();
          }}
        />
        <button
          type="submit"
          className={errorArray.length > 0 ? "disabled" : ""}
        >
          Add contact
        </button>
      </form>
    </section>
  );
};

export default Contacts;
