import { get, put, del, post } from "./DataService.js";

export const getCustomerExists = (customerNumber) => {
  return get(`/api/customers/${customerNumber}/exists`);
};

export const authUpdateCustomer = (customer) => {
  return put("/api/authcustomers", customer);
};

export const authGetContacts = (customerKey) => {
  return get(`/api/authcustomers/${customerKey}/contacts`);
};

export const authDeleteContact = (customerKey, contactKey) => {
  return del(`/api/authcustomers/${customerKey}/contacts/${contactKey}`);
};

export const authUpdateContact = (contact) => {
  return put(
    `/api/authcustomers/${contact.customerKey}/contacts/${contact.key}`,
    contact
  );
};

export const authInsertContact = (contact) => {
  return post(`/api/authcustomers/${contact.customerKey}/contacts/`, contact);
};

export const authGetLocations = (customerKey) => {
  return get(`/api/authcustomers/${customerKey}/locations`);
};

export const authDeleteLocation = (customerKey, locationKey) => {
  return del(`/api/authcustomers/${customerKey}/locations/${locationKey}`);
};

export const authUpdateLocation = (location) => {
  return put(
    `/api/authcustomers/${location.customerKey}/locations/${location.key}`,
    location
  );
};

export const authInsertLocation = (location) => {
  return post(
    `/api/authcustomers/${location.customerKey}/locations/`,
    location
  );
};

export const getUsersBySearchTerm = (term) => {
  return get(`/api/authcustomers/search/` + term);
};

export const authDeleteUser = (key) => {
  return del("/api/authcustomers/" + key);
};

export const authGetCustomerFinanceInfo = (custKey) => {
  return get("/api/authcustomers/" + custKey + "/finance");
};

export const authUpdateCustomerFinanceInfo = (custKey, info) => {
  return put("/api/authcustomers/" + custKey + "/finance", info);
};

export const authBatchInsertCustomers = (doc) => {
  const formData = new FormData();
  formData.append("doc", doc);

  return post("/api/authcustomers/batch", formData);
};

export const authGetCustomerCOD = () => {
  return get("/api/authcustomers/cod");
};
