import React, { useState, useEffect } from "react";

import { CustomerLocation } from "../../Assets/UtilityClasses.js";
import StatesList from "../../Assets/StatesList.js";

const Location = (props) => {
  const [errorArray, setErrorArray] = useState([]);
  const [formValues, setFormValues] = useState(new CustomerLocation());
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setFormValues({ ...props.location });
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const checkSomeValue = (name) => {
    if (formValues[name].length === 0) {
      if (!errorArray.includes(name)) {
        setErrorArray([...errorArray, name]);
      }
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== name;
        })
      );
    }
  };

  const handleEditing = async () => {
    if (
      isEditing &&
      JSON.stringify(formValues) !== JSON.stringify(props.location)
    ) {
      props.updateLocation({
        ...formValues,
        customerKey: parseInt(props.location.customerKey),
        key: parseInt(props.location.key),
        zipCode: parseInt(formValues.zipCode),
      });
    }
    setIsEditing(!isEditing);
    setErrorArray([]);
  };

  return (
    <>
      {errorArray.length > 0 && isEditing ? (
        <tr className="error">
          <td>Invalid values in:</td>
          {errorArray.map((cv) => {
            return <td key={cv}>{cv}</td>;
          })}
        </tr>
      ) : null}
      <tr key={props.location.key}>
        {isEditing ? (
          <>
            <td>
              <input
                value={formValues.address}
                name="address"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={() => {
                  checkSomeValue("address");
                }}
              />
            </td>
            <td>
              <input
                value={formValues.city}
                name="city"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={() => {
                  checkSomeValue("city");
                }}
              />
            </td>
            <td>
              <select
                defaultValue
                value={formValues.state}
                name="state"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={() => {
                  checkSomeValue("state");
                }}
              >
                <option disabled value>
                  {" "}
                  -- select an option --{" "}
                </option>
                {StatesList.map((state) => {
                  return <option key={state}>{state}</option>;
                })}
              </select>
            </td>
            <td>
              <input
                value={formValues.zipCode}
                name="zipCode"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={() => {
                  checkSomeValue("zipCode");
                }}
              />
            </td>
          </>
        ) : (
          <>
            <td>{props.location.address}</td>
            <td>{props.location.city}</td>
            <td>{props.location.state}</td>
            <td>{props.location.zipCode}</td>
          </>
        )}
        <td>
          <button
            onClick={() => {
              handleEditing();
            }}
          >
            ✎
          </button>
        </td>
        <td>
          <button
            onClick={() => {
              props.deleteLocation(props.location.key);
            }}
          >
            ❌
          </button>
        </td>
      </tr>
    </>
  );
};

export default Location;
