import React, { useState, useContext, useEffect } from "react";

import UserContext from "../../Contexts/UserContext.js";
import {
  authGetLocations,
  authDeleteLocation,
  authUpdateLocation,
  authInsertLocation,
} from "../../Adapters/CustomerAdapters.js";
import StatesList from "../../Assets/StatesList.js";
import CustomerLocation from "./CustomerLocation.js";

import "../../Styles/AccountPage/Contacts.scss";

const Contacts = (props) => {
  const { user } = useContext(UserContext);
  const [errorArray, setErrorArray] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [formValues, setFormValues] = useState({});

  const fetchLocations = async () => {
    var locations = await authGetLocations(props.userKey);
    setLocationList(locations.data);
  };

  useEffect(() => {
    fetchLocations();
  }, [user]);

  const deleteLocation = async (locationKey) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this location?"
    );
    if (confirmation) {
      await authDeleteLocation(locationKey.location, locationKey);
      await fetchLocations();
    }
  };

  const updateLocation = async (location) => {
    location.zipCode = parseInt(location.zipCode);
    await authUpdateLocation(location);
    await fetchLocations();
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const checkSomeValue = (name) => {
    if (formValues[name].length === 0) {
      if (!errorArray.includes(name)) {
        setErrorArray([...errorArray, name]);
      }
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== name;
        })
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (errorArray.length === 0) {
      await authInsertLocation({
        customerKey: parseInt(props.userKey),
        address: formValues.address,
        state: formValues.state,
        city: formValues.city,
        zipCode: parseInt(formValues.zipCode),
      });
      await fetchLocations();
      setFormValues({
        customerKey: "",
        address: "",
        state: "",
        city: "",
        zipCode: "",
      });
    }
  };

  return (
    <section className="contacts">
      <div className="tableContainer">
        <table>
          <thead>
            <tr>
              <th>Address</th>
              <th>City</th>
              <th>State</th>
              <th>Zipcode</th>
              <th>Edit</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {locationList.map((location) => {
              return (
                <CustomerLocation
                  key={location.key}
                  location={location}
                  deleteLocation={(locationKey) => {
                    deleteLocation(locationKey);
                  }}
                  updateLocation={(location) => {
                    updateLocation(location);
                  }}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <h2>New Location</h2>
      {errorArray.length > 0 ? (
        <ul className="error">
          <li>Invalid values in:</li>
          {errorArray.map((cv) => {
            return <li key={cv}>{cv}</li>;
          })}
        </ul>
      ) : null}
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <label htmlFor="address"> Address: </label>
        <input
          name="address"
          value={formValues.address}
          placeholder="670 N MacHabber"
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={() => {
            checkSomeValue("address");
          }}
        />
        <label htmlFor="state">State:</label>
        <select
          defaultValue
          name="state"
          value={formValues.state}
          onChange={(e) => {
            setFormValues({
              ...formValues,
              state: e.target.value,
            });
          }}
        >
          <option disabled value>
            {" "}
            -- select an option --{" "}
          </option>
          {StatesList.map((state) => {
            return <option key={state}>{state}</option>;
          })}
        </select>
        <label htmlFor="city">City:</label>
        <input
          name="city"
          value={formValues.city}
          placeholder="Phoenix"
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={() => {
            checkSomeValue("city");
          }}
        />
        <label htmlFor="zipCode">Zipcode:</label>
        <input
          name="zipCode"
          placeholder="85621"
          value={formValues.zipCode}
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={() => {
            checkSomeValue("zipCode");
          }}
        />
        <button
          type="submit"
          className={errorArray.length > 0 ? "disabled" : ""}
        >
          Add Location
        </button>
      </form>
    </section>
  );
};

export default Contacts;
