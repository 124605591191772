import React from "react"

import Logo from "../Assets/BHFHLogo.png"
import QRCode from "../Assets/BHFHQR.jpg"

import "../Styles/CharityBanner.scss";

const CharityBanner = () => {
    return <section className="charityBanner">
        <a href="https://www.buildinghomesforheroes.org/ways-to-donate/" target="_blank" rel="noreferrer">
            <img src={QRCode} alt="QR Code, scan to visit Building Homes for Heroes" />
            <img src={Logo} alt="Building Homes For Heroes Logo" />
        </a>
    </section>
}

export default CharityBanner