import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { authGetOrderByKey } from "../Adapters/OrderAdapters.js";
import LoadingSpinner from "../Components/LoadingSpinner.js";
import NoImage from "../Assets/NoImage.js";

import "../Styles/OrderPage.scss";

const HistoricalOrder = (props) => {
  const [order, setOrder] = useState({});
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getorder = async () => {
      const orderReq = await authGetOrderByKey(props.match.params.orderKey);
      setOrder(orderReq.data.order);
      setProducts(orderReq.data.products);
    };
    getorder();
  }, [props.match.params.orderKey]);

  const getDate = () => {
    const date = new Date(0);
    date.setSeconds(order.date);
    return date.toLocaleDateString();
  };

  return (
    <>
      {order && Object.keys(order).length > 0 ? (
        <section className="orderPage">
          <div>
            <p>
              Thank you so much for your order! A confirmation email has been
              sent. If you did not pay with CC then the cart total will be added
              to your NET account and will show on the next billing statement. A
              copy of the order has been sent off to the warehouse and we will
              begin the fulfillment process.
            </p>
            <p>
              If you have any questions regarding your order, please feel free
              to give your salesperson a call, or reach us at the main office
              at:
            </p>
            <a href="tel:6023232807">(602) 323-2807</a>
          </div>
          <h1>Order Number: {order.key}</h1>
          <h3>{getDate()}</h3>
          <table>
            <thead>
              <tr>
                <th>Product Image</th>
                <th>Product Number</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => {
                return (
                  <tr key={product.productKey}>
                    <td>
                      <img
                        src={
                          product.imageAddress
                            ? product.imageAddress
                            : NoImage()
                        }
                        alt="product"
                        onClick={() => {
                          props.history.push(`/product/${product.key}`);
                        }}
                      />
                    </td>
                    <td>{product.productId}</td>
                    <td>{product.description}</td>
                    <td>{product.quantity}</td>
                    <td>{product.price}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};
export default withRouter(HistoricalOrder);
