import React, { useState, useEffect } from "react";

import { getAllVendors } from "../../Adapters/VendorAdapters";

const EditVendorsSearch = (props) => {
  const [vendors, setVendors] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    const getvendorlist = async () => {
      const vendorlist = await getAllVendors();
      setVendors(vendorlist.data);
      setFilteredList(vendorlist.data);
    };
    getvendorlist();
  }, []);

  return (
    <section className="editVendors">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setFilteredList(
            vendors.filter((vend) => {
              return vend.name
                .toUpperCase()
                .includes(searchValue.toUpperCase());
            })
          );
        }}
      >
        <label htmlFor="search">Search vendors here:</label>
        <input
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
        <button type="submit">→</button>
      </form>
      <div className="vendorsList">
        <table className="vendorTable">
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {filteredList.map((vendor) => {
              return (
                <tr
                  key={vendor.key}
                  className="tableList"
                  onClick={() => {
                    props.setVendor(vendor);
                  }}
                >
                  <td>{vendor.name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default EditVendorsSearch;
