import React from "react";
import { Link } from "react-router-dom";

const CategoriesModal = (props) => {
  const allCategories = [...props.categories];
  const categoryGroups = [];

  if (allCategories && allCategories.length > 0) {
    if (allCategories.length > 10) {
      while (allCategories.length > 10 && categoryGroups.length < 2) {
        categoryGroups.push(allCategories.splice(0, 10));
      }
    } else {
      categoryGroups.push([...allCategories]);
    }
  }

  return (
    <>
      {categoryGroups.length > 0 ? (
        <section
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="categoriesModal"
        >
          {categoryGroups.map((group) => {
            return (
              <div key={categoryGroups.indexOf(group)}>
                <h3>
                  By category {group[0].name[0]}-
                  {group[group.length - 1].name[0]}
                </h3>
                {group.map((cat) => {
                  return (
                    <Link key={cat.key} to={`/product/categories/${cat.key}`}>
                      {cat.name}
                    </Link>
                  );
                })}
              </div>
            );
          })}
          <Link to="/allCategories">See all categories</Link>
        </section>
      ) : null}
    </>
  );
};

export default CategoriesModal;
