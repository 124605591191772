import React, { useState, useEffect } from "react";

import { authBatchInsertVendors } from "../../Adapters/VendorAdapters";
import { authGetAllPurchasingAgents } from "../../Adapters/PurchasingAgentAdapters";
import VendorReport from "../../Assets/vendor_report.jpg";

import "../../Styles/AdminPage/BatchAddItems.scss";

const BatchAddVendors = () => {
  const [doc, setDoc] = useState();
  const [agents, setAgents] = useState([]);
  const [agent, setAgent] = useState({});

  useEffect(() => {
    const getagents = async () => {
      const agentlist = await authGetAllPurchasingAgents();
      setAgents(agentlist.data);
    };
    getagents();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await authBatchInsertVendors(doc, agent.key);
    window.alert("Successfully added batch!");
  };

  return (
    <section className="batchAddVendors">
      <h2>Batch add vendors</h2>
      <p>
        Run report "Print Vendor List". This report MUST NOT CONTAIN THE
        HEADERS.
      </p>
      <p>Open in Excel and save as the .xlsx extension. It MUST BE .xlsx</p>
      <p>If your report does not look like this, it will not work</p>
      <img src={VendorReport} alt="screenshot of proper vendor report" />
      <p>Please note this process may take a while.</p>

      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <select
          defaultValue
          value={agent.name}
          onChange={(e) => {
            setAgent(
              agents.filter((cv) => {
                return cv.name === e.target.value;
              })[0]
            );
          }}
        >
          <option disabled value>
            {" "}
            -- select an option --{" "}
          </option>
          {agents.map((ag) => {
            return <option key={ag.key}>{ag.name}</option>;
          })}
        </select>
        <input
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={(e) => {
            setDoc(e.target.files[0]);
          }}
        />
        <button type="submit">Submit</button>
      </form>
    </section>
  );
};

export default BatchAddVendors;
