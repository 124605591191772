import React, { useState } from "react";

import EditItemsSearch from "./EditItemsSearch.js";
import EditItemsForm from "./EditItemsForm.js";

import "../../Styles/AdminPage/EditItems.scss";

const EditItems = () => {
  const [product, setProduct] = useState({});

  return product && Object.keys(product).length > 0 ? (
    <EditItemsForm
      setProduct={(prod) => {
        setProduct(prod);
      }}
      product={product}
    />
  ) : (
    <EditItemsSearch
      setProduct={(prod) => {
        setProduct(prod);
      }}
    />
  );
};

export default EditItems;
