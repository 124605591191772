import React, { useState } from "react";

import { authBatchInsertCustomers } from "../../Adapters/CustomerAdapters.js";
import CustomerReport from "../../Assets/customer_report.jpg"

import "../../Styles/AdminPage/BatchAddCustomers.scss";

const BatchAddCustomers = () => {
    const [doc, setDoc] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await authBatchInsertCustomers(doc);
        window.alert("Successfully added batch!");
    };

    return (
        <section className="batchAddCustomers">
            <h2>Batch add customers</h2>
            <p>
                Run report "Customers by Salesperson". This report MUST CONTAIN THE HEADERS.
            </p>
            <p>Open in Excel and save as the .xlsx extension. It MUST BE .xlsx</p>
            <p>If your report does not look like this, it will not work</p>
            <img src={CustomerReport} alt="screenshot of proper customer report"/>
            <p>Please note this process may take a while.</p>

            <p>
                All customers are added with a password of "@CTSisthebest!". Please
                remind the customer to change password.
            </p>

            <form
                onSubmit={(e) => {
                    handleSubmit(e);
                }}
            >
                <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={(e) => {
                        setDoc(e.target.files[0]);
                    }}
                />
                <button type="submit">Submit</button>
            </form>
        </section>
    );
};

export default BatchAddCustomers;
