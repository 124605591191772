import React, { useState } from "react";

import { authInsertPurchasingAgent } from "../../Adapters/PurchasingAgentAdapters";

const AddPurchasingAgent = (props) => {
  const [agent, setAgent] = useState({ name: "", phoneNumber: "", email: "" });
  const [errorArray, setErrorArray] = useState([]);

  const submittedAlert = () => {
    window.alert("Submitted succesfully");
  };

  const createAgent = async (e) => {
    e.preventDefault();
    await authInsertPurchasingAgent({
      name: agent.name,
      phoneNumber: parseFloat(agent.phoneNumber),
      email: agent.email,
    });
    submittedAlert();
  };

  const checkSomeValue = () => {
    if (agent.name.length === 0) {
      if (!errorArray.includes("name")) {
        setErrorArray([...errorArray, "name"]);
      }
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "name";
        })
      );
    }
  };

  const checkEmail = () => {
    if (/(@)(.+)$/gm.test(agent.email)) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "email";
        })
      );
    } else {
      setErrorArray([...errorArray, "email"]);
    }
  };

  const checkPhoneNumber = () => {
    if (/^[0-9]{10}$/gm.test(agent.phoneNumber)) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "phoneNumber";
        })
      );
    } else {
      if (!errorArray.includes("phoneNumber")) {
        setErrorArray([...errorArray, "phoneNumber"]);
      }
    }
  };

  return (
    <section className="editAgentsForm">
      <>
        <ul className={errorArray.length > 0 ? "errors" : "hidden"}>
          {errorArray.map((err) => {
            return <li key={err}>{err}</li>;
          })}
        </ul>
        <form
          onSubmit={(e) => {
            createAgent(e);
          }}
        >
          <h2>Agent Info</h2>
          <label htmlFor="name">Name:</label>
          <input
            value={agent.name}
            name="name"
            onChange={(e) => {
              setAgent({ ...agent, name: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue();
            }}
          />
          <label htmlFor="phoneNumber">Phone number:</label>
          <input
            value={agent.phoneNumber}
            name="phoneNumber"
            onChange={(e) => {
              setAgent({ ...agent, phoneNumber: e.target.value });
            }}
            onBlur={(e) => {
              checkPhoneNumber();
            }}
          />
          <label htmlFor="email">Email:</label>
          <input
            value={agent.email}
            name="email"
            onChange={(e) => {
              setAgent({ ...agent, email: e.target.value });
            }}
            onBlur={(e) => {
              checkEmail();
            }}
          />

          <button
            className={errorArray.length > 0 ? "disabled" : ""}
            type="submit"
          >
            Submit changes
          </button>
        </form>
      </>
    </section>
  );
};

export default AddPurchasingAgent;
