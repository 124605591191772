import React, { useState, useEffect } from "react";
import "../../Styles/AccountPage/Contacts.scss";

import { authInsertVendor } from "../../Adapters/VendorAdapters";
import { authGetAllPurchasingAgents } from "../../Adapters/PurchasingAgentAdapters";
import StatesList from "../../Assets/StatesList.js";

const AddVendor = (props) => {
  const [vendor, setVendor] = useState({});
  const [errorArray, setErrorArray] = useState([]);
  const [agents, setAgents] = useState([]);
  const [agent, setAgent] = useState({});

  useEffect(() => {
    const getagents = async () => {
      const agentlist = await authGetAllPurchasingAgents();
      setAgents(agentlist.data);
    };
    getagents();
  }, []);

  const submittedAlert = () => {
    window.alert("Submitted succesfully");
  };

  const insertVendor = async (e) => {
    e.preventDefault();
    try {
      await authInsertVendor({
        ...vendor,
        zipCode: parseInt(vendor.zipCode),
        distMethodKey: parseInt(vendor.distMethodKey),
        purchasingAgentKey: parseInt(agent.key),
        phoneNumber: parseFloat(vendor.phoneNumber),
        faxNumber: vendor.faxNumber ? parseFloat(vendor.faxNumber) : 0,
      });
      submittedAlert();
      setVendor({});
    } catch (err) {
      console.err(err);
      window.alert("Something went wrong!");
    }
  };

  const checkSomeValue = (key) => {
    if (!vendor[key] || vendor[key].length === 0) {
      if (!errorArray.includes(key)) {
        setErrorArray([...errorArray, key]);
      }
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== key;
        })
      );
    }
  };

  const checkPhoneNumber = (key) => {
    if (!vendor[key] || /^[0-9]{10}$/gm.test(vendor[key])) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== key;
        })
      );
    } else {
      if (!errorArray.includes(key)) {
        setErrorArray([...errorArray, key]);
      }
    }
  };

  return (
    <section className="addVendors">
      <>
        <ul className={errorArray.length > 0 ? "errors" : "hidden"}>
          {errorArray.map((err) => {
            return <li>{err}</li>;
          })}
        </ul>
        <form
          onSubmit={(e) => {
            insertVendor(e);
          }}
        >
          <h2>Vendor Info</h2>
          <label htmlFor="name">Name:</label>
          <input
            value={vendor.name}
            name="name"
            onChange={(e) => {
              setVendor({ ...vendor, name: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("name");
            }}
          />

          <label htmlFor="id">Id:</label>
          <input
            value={vendor.id}
            name="id"
            onChange={(e) => {
              setVendor({ ...vendor, id: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("id");
            }}
          />

          <label htmlFor="address">Address:</label>
          <input
            value={vendor.address}
            name="address"
            onChange={(e) => {
              setVendor({ ...vendor, address: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("address");
            }}
          />

          <label htmlFor="state">State:</label>
          <select
            defaultValue
            value={vendor.state}
            name="state"
            onChange={(e) => {
              setVendor({
                ...vendor,
                state: e.target.value,
              });
            }}
          >
            <option disabled value>
              {" "}
              -- select an option --{" "}
            </option>
            {StatesList.map((state) => {
              return <option key={state}>{state}</option>;
            })}
          </select>

          <label htmlFor="city">City:</label>
          <input
            value={vendor.city}
            name="city"
            onChange={(e) => {
              setVendor({ ...vendor, city: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("city");
            }}
          />

          <label htmlFor="zipCode">Zip code:</label>
          <input
            value={vendor.zipCode}
            name="zipCode"
            onChange={(e) => {
              setVendor({ ...vendor, zipCode: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("zipCode");
            }}
          />

          <label htmlFor="federalTaxId">Federal Tax Id:</label>
          <input
            value={vendor.federalTaxId}
            name="federalTaxId"
            onChange={(e) => {
              setVendor({ ...vendor, federalTaxId: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("federalTaxId");
            }}
          />

          <label htmlFor="accountNumber">Account Number:</label>
          <input
            value={vendor.accountNumber}
            name="accountNumber"
            onChange={(e) => {
              setVendor({ ...vendor, accountNumber: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("accountNumber");
            }}
          />

          <label htmlFor="distMethodKey">Dist Method Key:</label>
          <input
            type="number"
            min="0"
            value={vendor.distMethodKey}
            name="distMethodKey"
            onChange={(e) => {
              setVendor({ ...vendor, distMethodKey: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("distMethodKey");
            }}
          />

          <label htmlFor="terms">Terms:</label>
          <input
            value={vendor.terms}
            name="terms"
            onChange={(e) => {
              setVendor({ ...vendor, terms: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("terms");
            }}
          />

          <label htmlFor="ourAccountNumber">Our Account Number:</label>
          <input
            value={vendor.ourAccountNumber}
            name="ourAccountNumber"
            onChange={(e) => {
              setVendor({ ...vendor, ourAccountNumber: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("ourAccountNumber");
            }}
          />

          <label htmlFor="contactName">Contact name:</label>
          <input
            value={vendor.contactName}
            name="contactName"
            onChange={(e) => {
              setVendor({ ...vendor, contactName: e.target.value });
            }}
            onBlur={(e) => {
              checkSomeValue("contactName");
            }}
          />

          <label htmlFor="phoneNumber">Phone number:</label>
          <input
            type="number"
            min="0"
            value={vendor.phoneNumber}
            name="phoneNumber"
            onChange={(e) => {
              setVendor({ ...vendor, phoneNumber: e.target.value });
            }}
            onBlur={(e) => {
              checkPhoneNumber("phoneNumber");
            }}
          />

          <label htmlFor="faxNumber">Fax number:</label>
          <input
            type="number"
            min="0"
            value={vendor.faxNumber}
            name="faxNumber"
            onChange={(e) => {
              setVendor({ ...vendor, faxNumber: e.target.value });
            }}
            onBlur={(e) => {
              checkPhoneNumber("faxNumber");
            }}
          />

          <label htmlFor="purchasingAgentKey">Purchasing Agent:</label>
          <select
            defaultValue
            value={agent.name}
            name="purchasingAgentKey"
            onChange={(e) => {
              const newAgent = agents.filter((cv) => {
                return cv.name === e.target.value;
              })[0];
              setAgent(newAgent);
            }}
          >
            <option disabled value>
              {" "}
              -- select an option --{" "}
            </option>
            {agents.map((ag) => {
              return <option key={ag.key}>{ag.name}</option>;
            })}
          </select>

          <button
            className={errorArray.length > 0 ? "disabled" : ""}
            type="submit"
          >
            Submit changes
          </button>
        </form>
      </>
    </section>
  );
};

export default AddVendor;
