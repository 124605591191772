import React, { useState, useEffect } from "react";

import "../../Styles/AdminPage/ManageCategories.scss";

import {
  authDeleteCategory,
  authUpdateCategory,
  authInsertCategory,
  getAllCategories,
} from "../../Adapters/ProductAdapters.js";

const ManageCategories = () => {
  const [categories, setCategories] = useState([]);
  const [editDict, setEditDict] = useState({});
  const [catName, setCatName] = useState("");
  const [catChecked, setCatChecked] = useState(false);

  const [formName, setFormName] = useState("");
  const [formChecked, setFormChecked] = useState(false);

  useEffect(() => {
    const getcatlist = async () => {
      const catList = await getAllCategories();
      setCategories(catList.data);
    };
    getcatlist();
  }, []);

  return (
    <section className="manageCategories">
      <h2>Manage categories</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Featured</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((cat) => {
            return (
              <tr key={cat.key}>
                <td>
                  {editDict[cat.key] ? (
                    <input
                      value={catName}
                      onChange={(e) => {
                        setCatName(e.target.value);
                      }}
                    />
                  ) : (
                    cat.name
                  )}
                </td>
                <td>
                  {editDict[cat.key] ? (
                    <input
                      type="checkbox"
                      checked={catChecked}
                      onChange={(e) => {
                        setCatChecked(e.target.checked);
                      }}
                    />
                  ) : cat.featured ? (
                    "Yes"
                  ) : (
                    "No"
                  )}
                </td>
                <td>
                  <button
                    onClick={async () => {
                      const newEditDict = {};
                      for (const key in editDict) {
                        newEditDict[key] = false;
                      }
                      if (editDict[cat.key]) {
                        await authUpdateCategory({
                          key: cat.key,
                          name: catName,
                          featured: catChecked,
                        });
                        let categoriesCopy = categories;
                        for (let i = 0; i < categories.length; i++) {
                          if (categoriesCopy[i].key === cat.key) {
                            categoriesCopy[i].name = catName;
                            categoriesCopy[i].featured = catChecked;
                          }
                        }
                        setCategories(categoriesCopy);
                        setEditDict(newEditDict);
                      } else {
                        setEditDict({ ...newEditDict, [cat.key]: true });
                        setCatName(cat.name);
                        setCatChecked(cat.featured);
                      }
                    }}
                  >
                    ✎
                  </button>
                </td>
                <td>
                  <button
                    onClick={async () => {
                      const confirm = window.confirm(
                        "Are you sure you wish to remove this category?"
                      );
                      if (confirm) {
                        try {
                          await authDeleteCategory(cat.key);
                          setCategories(
                            categories.filter((ct) => cat.key !== ct.key)
                          );
                        } catch (err) {
                          console.error(err);
                          window.alert(
                            "There was a problem removing this category. Perhaps products are assigned to it."
                          );
                        }
                      }
                    }}
                  >
                    ✖
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            const newId = await authInsertCategory({
              name: formName,
              featured: formChecked,
            });
            setCategories([
              ...categories,
              { key: newId.data, name: formName, featured: formChecked },
            ]);
            setFormChecked(false);
            setFormName("");
          } catch (err) {
            console.error(err);
            window.alert("Error creating category");
          }
        }}
      >
        <h2>New Category</h2>
        <label htmlFor="formname">Name:</label>
        <input
          name="formname"
          value={formName}
          onChange={(e) => {
            setFormName(e.target.value);
          }}
        />
        <label htmlFor="formchecked">Featured:</label>
        <input
          type="checkbox"
          name="formchecked"
          checked={formChecked}
          onChange={(e) => {
            setFormChecked(e.target.checked);
          }}
        />
        <button type="submit">Submit</button>
      </form>
    </section>
  );
};

export default ManageCategories;
