import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import ProductList from "../Components/ProductList.js";
import LoadingSpinner from "../Components/LoadingSpinner.js";
import { getProductsBySearchTerm } from "../Adapters/ProductAdapters.js";

import "../Styles/SearchTermPage.scss";

const SearchTermPage = (props) => {
  const [products, setProducts] = useState([]);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    const getproducts = async () => {
      const products = await getProductsBySearchTerm(
        props.match.params.searchTerm
      );

      if (products.data.length === 0) {
        setNoResults(true);
      }

      setProducts(products.data);
    };
    getproducts();
  }, [props.match.params.searchTerm]);
  return (
    <>
      {products && products.length > 0 ? (
        <section className="searchTermPage">
          <span>
            <Link to="/">Home</Link> {"→"} {props.match.params.searchTerm}
          </span>
          <h2>Viewing category results for {props.match.params.searchTerm}</h2>
          <ProductList products={products} />
        </section>
      ) : noResults ? (
        <h3 className="noResults">
          Oops, there doesn't seem to be any results for:{" "}
          {props.match.params.searchTerm}
        </h3>
      ) : (
        <LoadingSpinner height="300px" />
      )}
    </>
  );
};

export default SearchTermPage;
