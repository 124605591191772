import React, { useContext } from "react";
import { Link } from "react-router-dom";

import UserContext from "../../Contexts/UserContext.js";
import CartContext from "../../Contexts/CartContext.js";

import "../../Styles/HeaderFooter/AccessButtons.scss";

const AccessButtons = () => {
  const { user, setUser } = useContext(UserContext);
  const { cart, setCart } = useContext(CartContext);

  return (
    <section className="accessButtons">
      {user.token ? (
        <>
          <h2>Hello {user.user.name}</h2>
          <Link
            className={cart && cart.length > 0 ? "items" : "inactive"}
            to={cart && cart.length > 0 ? "/cart" : ""}
          >
            🛒
          </Link>
          <Link to={user.user.isAdmin ? "/admin" : "/account"}>💳</Link>
          <Link
            onClick={() => {
              setUser({});
              setCart([]);
              localStorage.clear();
            }}
            to="/"
          >
            Sign out →
          </Link>
        </>
      ) : (
        <>
          <Link to="/login">Sign in {"→"}</Link>
          <Link to="/register">Register {"→"}</Link>
        </>
      )}
    </section>
  );
};

export default AccessButtons;
