import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import "../../Styles/HeaderFooter/SearchBar.scss";

const SearchBar = (props) => {
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.history.push(`/product/search/${searchValue}`);
    setSearchValue("");
  };

  return (
    <section className="searchBar">
      <h1>Construction Tool & Supply</h1>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <input
          type="text"
          placeholder="Search entire store here"
          onChange={(e) => {
            handleChange(e);
          }}
          value={searchValue}
        />
        <button type="submit">{"→"}</button>
      </form>
    </section>
  );
};

export default withRouter(SearchBar);
