import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getFeaturedCategories } from "../Adapters/ProductAdapters.js";
import LoadingSpinner from "./LoadingSpinner.js";

import "../Styles/FeaturedCategories.scss";

const FeaturedCategories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getandsetcategories = async () => {
      const categories = await getFeaturedCategories();
      const stateCopy = categories.data;
      const dataGroups = [];
      const numberOfColumns = Math.ceil(stateCopy.length / 5);

      for (var i = 0; i < numberOfColumns; i++) {
        if (stateCopy.length >= 5) {
          dataGroups.push(stateCopy.splice(0, 5));
        } else {
          dataGroups.push(stateCopy.splice(0, stateCopy.length));
        }
      }
      setCategories(dataGroups);
    };
    getandsetcategories()
  }, []);

  return (
    <>
      {categories && categories.length > 0 ? (
        <section className="featuredCategories">
          <h2>Featured categories</h2>
          <div className="featuredCategories-container">
            {categories.map((group) => {
              return (
                <div
                  className="featuredCategories-group"
                  key={categories.indexOf(group)}
                >
                  {group.map((category) => {
                    return (
                      <Link
                        to={`/product/categories/${category.key}`}
                        key={category.key}
                      >
                        {category.name}
                      </Link>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </section>
      ) : (
        <LoadingSpinner height="300px" />
      )}
    </>
  );
};

export default FeaturedCategories;
