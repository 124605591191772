import React, { useState } from "react";

import EditPurchasingAgentsSearch from "./EditPurchasingAgentsSearch.js";
import EditPurchasingAgentsForm from "./EditPurchasingAgentsForm.js";

import "../../Styles/AdminPage/EditPurchasingAgents.scss";

const EditPurchasingAgents = () => {
  const [agent, setAgent] = useState({});

  return agent && Object.keys(agent).length > 0 ? (
    <EditPurchasingAgentsForm
      setAgent={(obj) => {
        setAgent(obj);
      }}
      agent={agent}
    />
  ) : (
    <EditPurchasingAgentsSearch
      setAgent={(agent) => {
        setAgent(agent);
      }}
    />
  );
};

export default EditPurchasingAgents;
