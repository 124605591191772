import React, { useState, useContext, useEffect } from "react";

import UserContext from "../../Contexts/UserContext.js";
import {
  authGetLocations,
  authDeleteLocation,
  authUpdateLocation,
  authInsertLocation,
} from "../../Adapters/CustomerAdapters.js";
import Location from "./Location.js";
import { CustomerLocation } from "../../Assets/UtilityClasses.js";
import StatesList from "../../Assets/StatesList.js";

import "../../Styles/AccountPage/Locations.scss";

const Locations = () => {
  const { user } = useContext(UserContext);
  const [errorArray, setErrorArray] = useState([]);
  const [locationList, setLocationListList] = useState([]);
  const [formValues, setFormValues] = useState(new CustomerLocation());

  const fetchLocations = async () => {
    var locations = await authGetLocations(user.user.key);
    setLocationListList(locations.data);
  };

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      fetchLocations();
    }
  }, [user]);

  const deleteLocation = async (locationKey) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this location?"
    );
    if (confirmation) {
      await authDeleteLocation(user.user.key, locationKey);
      await fetchLocations();
    }
  };

  const updateLocation = async (location) => {
    await authUpdateLocation(location);
    await fetchLocations();
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const checkSomeValue = (name) => {
    if (formValues[name].length === 0) {
      if (!errorArray.includes(name)) {
        setErrorArray([...errorArray, name]);
      }
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== name;
        })
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (errorArray.length === 0) {
      await authInsertLocation({
        city: formValues.city,
        state: formValues.state,
        address: formValues.address,
        zipcode: parseInt(formValues.zipCode),
        customerKey: parseInt(user.user.key),
      });
      await fetchLocations();
      setFormValues(new CustomerLocation());
    }
  };
  return (
    <section className="locations">
      <h2>Delivery Locations</h2>
      <div className="tableContainer">
        <table>
          <thead>
            <tr>
              <th>Address</th>
              <th>City</th>
              <th>State</th>
              <th>ZipCode</th>
              <th>Edit</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {locationList.map((location) => {
              return (
                <Location
                  key={location.key}
                  location={location}
                  deleteLocation={(locationKey) => {
                    deleteLocation(locationKey);
                  }}
                  updateLocation={(location) => {
                    updateLocation(location);
                  }}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <h2>New delivery location</h2>
      {errorArray.length > 0 ? (
        <ul className="error">
          <li>Invalid values in:</li>
          {errorArray.map((cv) => {
            return <li key={cv}>{cv}</li>;
          })}
        </ul>
      ) : null}
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <label htmlFor="address">Address:</label>
        <input
          name="address"
          placeholder="1234 Example St"
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={() => {
            checkSomeValue("address");
          }}
        />
        <label htmlFor="city">City:</label>
        <input
          name="city"
          placeholder="Exampopolis"
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={() => {
            checkSomeValue("city");
          }}
        />
        <label htmlFor="state">State:</label>
        <select
          defaultValue
          name="state"
          placeholder="TE"
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={() => {
            checkSomeValue("state");
          }}
        >
          <option disabled value>
            {" "}
            -- select an option --{" "}
          </option>
          {StatesList.map((state) => {
            return <option key={state}>{state}</option>;
          })}
        </select>
        <label htmlFor="zipCode">Zip code:</label>
        <input
          name="zipCode"
          placeholder="12345"
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={() => {
            checkSomeValue("zipCode");
          }}
        />
        <button
          type="submit"
          className={errorArray.length > 0 ? "disabled" : ""}
        >
          Add location
        </button>
      </form>
    </section>
  );
};

export default Locations;
