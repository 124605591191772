import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

import { postLoginCredentials } from "../Adapters/AuthAdapters.js";
import UserContext from "../Contexts/UserContext.js";
import { ErrorStatus } from "../Assets/UtilityClasses.js";
import ErrorMessage from "../Components/ErrorMessage.js";

import "../Styles/LoginPage.scss";

const LoginPage = (props) => {
  const loginObject = { customerNumber: "", password: "" };

  const [formValue, setFormValue] = useState(loginObject);
  const [errorStatus, setErrorStatus] = useState(new ErrorStatus());
  const { setUser } = useContext(UserContext);

  const handleChange = (e) => {
    e.preventDefault();
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postLoginCredentials(formValue)
      .then((res) => {
        setUser({
          ...res.data,
          user: {
            ...res.data.user,
            isAdmin: res.data.user.pricingTier === 42069,
          },
        });
        setErrorStatus(new ErrorStatus());
        setFormValue(loginObject);
        props.history.push("/");
      })
      .catch((err) => {
        setErrorStatus({
          status: true,
          code: err.response.data.status,
          message: err.response.data.title,
        });
      });
  };

  return (
    <section className="loginPage">
      {errorStatus.status ? <ErrorMessage error={errorStatus} /> : null}
      <ul>
        <li>
          <h2>Log in:</h2>
          <p>Already have an account? Please enter your credentials below</p>
        </li>
        <li>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <label htmlFor="customerNumber">Customer Number:</label>
            <input
              type="number"
              min="0"
              name="customerNumber"
              placeholder="Customer Number ( ex. 1234 )"
              onChange={(e) => {
                handleChange(e);
              }}
              value={formValue.customerNumber}
            />
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={(e) => {
                handleChange(e);
              }}
              value={formValue.password}
            />
            <button type="submit">Login</button>
          </form>
        </li>
        <li>
          <h2>Don't have an account?</h2>
          <p>
            You can register for a new account <Link to="/register">here</Link>.
          </p>
        </li>
        <li>
          <h2>Forgot your login information?</h2>
          <p>
            Please call your sales representative, or our main line for
            assistance
          </p>
          <a href="tel:+1-602-323-2807">(602) 323-2807</a>
        </li>
      </ul>
    </section>
  );
};

export default LoginPage;
