import React from "react";

import LoadingSpinner from "./LoadingSpinner.js";
import ProductListView from "./ProductListView.js";

const ProductList = (props) => {
  return (
    <>
      {props.products && props.products.length > 0 ? (
        <section className="productList">
          {props.products.map((cv) => {
            return <ProductListView product={cv} key={cv.key} />;
          })}
        </section>
      ) : (
        <LoadingSpinner height="300px" />
      )}
    </>
  );
};

export default ProductList;
