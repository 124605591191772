import React from "react";

import Logo from "./Logo.js";
import SearchBar from "./SearchBar.js";
import AccessButtons from "./AccessButtons.js";

import "../../Styles/HeaderFooter/Header.scss";

const Header = () => {
  return (
    <section className="header">
      <div className="header-left">
        <Logo />
        <SearchBar />
      </div>
      <div className="header-right">
        <AccessButtons />
      </div>
    </section>
  );
};

export default Header;
