import React, { useState, useEffect } from "react";

import StatesList from "../../Assets/StatesList.js";
import { getCustomerExists } from "../../Adapters/CustomerAdapters.js";

const RegistrationStep1 = (props) => {
  const [customer, setCustomer] = useState(props.customer);
  const [errorArray, setErrorArray] = useState([]);
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    const errorArrayIsValid = errorArray.length === 0;
    const customerIsValid =
      Object.keys(customer).filter((prop) => {
        return customer[prop].length === 0 && prop !== "faxNumber";
      }).length === 0;

    setCanSubmit(errorArrayIsValid && customerIsValid);
  }, [errorArray, customer]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (canSubmit) {
      props.setCustomer(customer);
      props.setPageNumber(2);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setCustomer({ ...customer, [e.target.name]: e.target.value });
  };

  const checkCustomerIfExists = async () => {
    const customerExists = await getCustomerExists(customer.customerNumber);
    if (customerExists.data) {
      setErrorArray([...errorArray, "customerNumber"]);
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "customerNumber";
        })
      );
    }
  };

  const confirmPassword = () => {
    const match = customer.password === customer.confirmPassword;
    if (match) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "password";
        })
      );
    } else {
      setErrorArray([...errorArray, "password"]);
    }
  };

  const checkPhoneNumber = () => {
    if (/^[0-9]{10}$/gm.test(customer.phoneNumber)) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "phoneNumber";
        })
      );
    } else {
      if (!errorArray.includes("phoneNumber")) {
        setErrorArray([...errorArray, "phoneNumber"]);
      }
    }
  };

  const checkZipcode = () => {
    if (customer.zipCode.length !== 5) {
      setErrorArray([...errorArray, "zipCode"]);
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "zipCode";
        })
      );
    }
  };

  const checkSomeValue = (name) => {
    if (customer[name].length === 0) {
      if (!errorArray.includes(name)) {
        setErrorArray([...errorArray, name]);
      }
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== name;
        })
      );
    }
  };

  const generateNumber = () => {
    setCustomer({
      ...customer,
      customerNumber: Math.floor(Math.random() * 90000) + 10000,
    });
  };

  return (
    <section className="registrationStep registrationStep1">
      <h2>Let's start by gathering some information about your company</h2>
      <p>
        Please fill out the form below as accurately as possible. You will have
        the ability to change this information later, but incorrect or
        inaccurate information may cause issues with shipping.
      </p>
      <p>Note: refreshing or changing pages may cause you to lose progress.</p>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <ul>
          <li>
            <p>
              First, we need a customer number. This will be a unique number
              used to identify and login to your account. Do not lose this
              number.
            </p>
            <button
              onClick={() => {
                generateNumber();
              }}
            >
              Pick one for me
            </button>
            <label htmlFor="customerNumber">Customer number:</label>
            <input
              type="number"
              min="0"
              name="customerNumber"
              placeholder="1234"
              value={customer.customerNumber}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={() => {
                checkCustomerIfExists();
              }}
            />
            {errorArray.includes("customerNumber") ? (
              <p className="error">That customer number already exists</p>
            ) : null}
          </li>
          <li>
            <p>Please choose a secure password.</p>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={customer.password}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={() => {
                confirmPassword();
              }}
            />
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={customer.confirmPassword}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={() => {
                confirmPassword();
              }}
            />
            {errorArray.includes("password") ? (
              <p className="error">Passwords don't match</p>
            ) : null}
          </li>
          <li>
            <p>
              Now we need a business phone number. If this account is for an
              individual instead of an entity, use your own phone number.
            </p>
            <label htmlFor="phoneNumber">Business phone number:</label>
            <input
              type="number"
              min="0"
              name="phoneNumber"
              placeholder="4801234567"
              value={customer.phoneNumber}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={() => {
                checkPhoneNumber();
              }}
            />
            {errorArray.includes("phoneNumber") ? (
              <p className="error">
                Invalid phone number. Please enter your 10 digit phone number.
                Example: 1234567890
              </p>
            ) : null}
          </li>
          <li>
            <p>
              What is the name of your business? If this is for an individual,
              use your name.
            </p>
            <label htmlFor="name">Business name:</label>
            <input
              type="text"
              name="name"
              placeholder="Dunder Mifflin"
              value={customer.name}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e) => {
                checkSomeValue(e.target.name);
              }}
            />
            {errorArray.includes("name") ? (
              <p className="error">Invalid name, must have value</p>
            ) : null}
          </li>
          <li>
            <p>
              Please fill out the address information. This is also the danger
              zone. Take a moment to make sure it is all correct to avoid
              shipping problems.
            </p>
            <div className="addressBlock">
              <div className="addressBlockSection">
                <label htmlFor="address">Street address:</label>
                <input
                  type="text"
                  name="address"
                  placeholder="1234 Example St"
                  value={customer.address}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    checkSomeValue(e.target.name);
                  }}
                />
                {errorArray.includes("address") ? (
                  <p className="error">Invalid address, must have value</p>
                ) : null}
              </div>
              <div className="addressBlockSection">
                <label htmlFor="city">City:</label>
                <input
                  type="text"
                  name="city"
                  placeholder="Phoenix"
                  value={customer.city}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    checkSomeValue(e.target.name);
                  }}
                />
                {errorArray.includes("city") ? (
                  <p className="error">Invalid city, must have value</p>
                ) : null}
              </div>
              <div className="addressBlockSection">
                <label htmlFor="state">State:</label>
                <select
                  defaultValue
                  name="state"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    checkSomeValue(e.target.name);
                  }}
                  value={customer.state}
                >
                  <option disabled value>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  {StatesList.map((state) => {
                    return <option key={state}>{state}</option>;
                  })}
                </select>
                {errorArray.includes("state") ? (
                  <p className="error">Invalid state, must have value</p>
                ) : null}
              </div>
              <div className="addressBlockSection">
                <label htmlFor="zipCode">Zipcode:</label>
                <input
                  type="number"
                  min="0"
                  name="zipCode"
                  placeholder="12345"
                  value={customer.zipCode}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={() => {
                    checkZipcode();
                  }}
                />
                {errorArray.includes("zipCode") ? (
                  <p className="error">Invalid zipcode. Must be 5 digits</p>
                ) : null}
              </div>
            </div>
          </li>
          <li>
            <label htmlFor="faxNumber">Fax number: (optional)</label>
            <input
              type="number"
              min="0"
              name="faxNumber"
              placeholder="4801234567"
              value={customer.faxNumber}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </li>
        </ul>
        <button
          type="submit"
          className={`submitButton ${canSubmit ? "" : "inactive"}`}
        >
          Next →
        </button>
      </form>
    </section>
  );
};

export default RegistrationStep1;
