import React, { useEffect, useState } from "react";

import { getAllVendors } from "../../Adapters/VendorAdapters.js";
import { authBatchInsertProduct } from "../../Adapters/ProductAdapters";
import ItemReport from "../../Assets/item_report.jpg";

import "../../Styles/AdminPage/BatchAddItems.scss";

const BatchAddItems = () => {
  const [vendors, setVendors] = useState([]);
  const [vendor, setVendor] = useState();
  const [doc, setDoc] = useState();
  const [typeAheadText, setTypeAheadText] = useState("");
  const [vendorSelected, setVendorSelected] = useState(false);

  useEffect(() => {
    const getvendors = async () => {
      const vendorList = await getAllVendors();
      setVendors(vendorList.data);
    };
    getvendors();
  }, []);

  const matchVendor = (name) => {
    return vendors.filter((vendor) => {
      return vendor.name === name;
    })[0];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await authBatchInsertProduct({ doc: doc, vendorKey: vendor });
    window.alert("Successfully added batch!");
  };

  return (
    <section className="batchAddItems">
      <h2>Batch add items</h2>
      <p>
        Run report "Item Master List" with a filter of "By Vendor". This report
        MUST CONTAIN THE HEADERS.
      </p>
      <p>Open in Excel and save as the .xlsx extension. It MUST BE .xlsx</p>
      <p>If your report does not look like this, it will not work</p>
      <img src={ItemReport} alt="screenshot of proper item report" />
      <p>Please note this process may take a while.</p>

      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <input
          className="typeahead-input"
          type="text"
          placeholder="Begin typing vendor name..."
          value={typeAheadText}
          onChange={(e) => {
            setTypeAheadText(e.target.value.toUpperCase());
            if (vendorSelected) {
              setVendorSelected(false);
            }
          }}
        />
        <ul className="typeahead">
          {!vendorSelected
            ? vendors
                .filter((vendor) => {
                  return typeAheadText
                    ? vendor.name.includes(typeAheadText)
                    : false;
                })
                .map((vendor) => {
                  return (
                    <li
                      key={Math.random() + vendor.name}
                      onClick={() => {
                        setVendor(matchVendor(vendor.name).key);
                        setTypeAheadText(vendor.name);
                        setVendorSelected(true);
                      }}
                    >
                      {vendor.name}
                    </li>
                  );
                })
            : ""}
        </ul>
        <input
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={(e) => {
            setDoc(e.target.files[0]);
          }}
        />
        <button type="submit">Submit</button>
      </form>
    </section>
  );
};

export default BatchAddItems;
