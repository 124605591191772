import React, { useEffect, useState } from "react";

import {
  authGetFullProductDetails,
  getAllCategories,
  authUpdateProduct,
  authDeleteProduct,
  authUpdateProductPricingInfo,
} from "../../Adapters/ProductAdapters.js";
import { getAllVendors } from "../../Adapters/VendorAdapters.js";

import LoadingSpinner from "../LoadingSpinner.js";

const EditItemForm = (props) => {
  const [fullProductDetails, setFullProductDetails] = useState({});
  const [vendors, setVendors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const [typeAheadText, setTypeAheadText] = useState("");
  const [vendorSelected, setVendorSelected] = useState(false);

  useEffect(() => {
    const getdetails = async () => {
      const details = await authGetFullProductDetails(props.product.key);
      const categoryList = await getAllCategories();
      const vendorList = await getAllVendors();

      setFullProductDetails(() => details.data);
      setVendors(vendorList.data);
      setCategories(categoryList.data);
    };
    getdetails();
  }, []);

  useEffect(() => {
    setTypeAheadText(fullProductDetails?.product?.vendor?.name);
    setVendorSelected(true);
  }, [fullProductDetails]);

  const matchVendor = (name) => {
    return vendors.filter((vendor) => {
      return vendor.name === name;
    })[0];
  };

  const matchCategory = (name) => {
    return categories.filter((category) => {
      return category.name === name;
    })[0];
  };

  const goBack = () => {
    props.setProduct({});
  };

  const updateProduct = async (e) => {
    if (errorArray.length > 0) {
      return;
    }
    e.preventDefault();
    const updatedProduct = {
      ...fullProductDetails.product,
      standardCost: parseFloat(fullProductDetails.product.standardCost),
      basePrice: parseFloat(fullProductDetails.product.basePrice),
      minimumQuantity: parseInt(fullProductDetails.product.minimumQuantity),
      maximumQuantity: parseInt(fullProductDetails.product.maximumQuantity),
      defaultQuantity: parseInt(fullProductDetails.product.defaultQuantity),
    };
    await authUpdateProduct(updatedProduct);
    goBack();
  };

  const deleteProduct = async () => {
    const confirm = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirm) {
      await authDeleteProduct(fullProductDetails.product.key);
      goBack();
    }
  };

  const updatePricing = async (e) => {
    if (errorArray.length > 0) {
      return;
    }
    e.preventDefault();
    await authUpdateProductPricingInfo(fullProductDetails.pricingInfo);
    goBack();
  };

  return (
    <section className="editItemForm">
      {fullProductDetails &&
      fullProductDetails.product &&
      vendors &&
      vendors.length > 0 &&
      categories &&
      categories.length > 0 ? (
        <>
          <button
            onClick={() => {
              goBack();
            }}
          >
            ←
          </button>
          <h2>Product details:</h2>
          <ul className={errorArray.length > 0 ? "errors" : "hidden"}>
            {errorArray.map((err) => {
              return <li>{err}</li>;
            })}
          </ul>
          <form
            onSubmit={(e) => {
              updateProduct(e);
            }}
          >
            <label htmlFor="productId">Product Id</label>
            <input
              name="productId"
              value={fullProductDetails.product.productId}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  product: {
                    ...fullProductDetails.product,
                    productId: e.target.value,
                  },
                });
              }}
              onBlur={() => {
                if (fullProductDetails.product.productId.length < 1) {
                  if (!errorArray.includes("productId")) {
                    setErrorArray([...errorArray, "productId"]);
                  }
                } else {
                  setErrorArray(
                    errorArray.filter((err) => {
                      return err !== "productId";
                    })
                  );
                }
              }}
            />

            <label htmlFor="auxilliaryItemId">Auxilliary Item Id</label>
            <input
              name="auxilliaryItemId"
              value={fullProductDetails.product.auxilliaryItemId}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  product: {
                    ...fullProductDetails.product,
                    auxilliaryItemId: e.target.value,
                  },
                });
              }}
              onBlur={() => {
                if (fullProductDetails.product.auxilliaryItemId.length < 1) {
                  if (!errorArray.includes("auxilliaryItemId")) {
                    setErrorArray([...errorArray, "auxilliaryItemId"]);
                  }
                } else {
                  setErrorArray(
                    errorArray.filter((err) => {
                      return err !== "auxilliaryItemId";
                    })
                  );
                }
              }}
            />

            <label htmlFor="description">Description</label>
            <input
              name="description"
              value={fullProductDetails.product.description}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  product: {
                    ...fullProductDetails.product,
                    description: e.target.value,
                  },
                });
              }}
              onBlur={() => {
                if (fullProductDetails.product.description.length < 1) {
                  if (!errorArray.includes("description")) {
                    setErrorArray([...errorArray, "description"]);
                  }
                } else {
                  setErrorArray(
                    errorArray.filter((err) => {
                      return err !== "description";
                    })
                  );
                }
              }}
            />

            <label htmlFor="vendor">Vendor</label>
            <input
              className="typeahead-input"
              type="text"
              placeholder="Begin typing vendor name..."
              value={typeAheadText}
              onChange={(e) => {
                setTypeAheadText(e.target.value.toUpperCase());
                if (vendorSelected) {
                  setVendorSelected(false);
                }
              }}
            />
            <ul className="typeahead">
              {!vendorSelected
                ? vendors
                    .filter((vendor) => {
                      return typeAheadText
                        ? vendor.name.includes(typeAheadText)
                        : false;
                    })
                    .map((vendor) => {
                      return (
                        <li
                          key={Math.random() + vendor.name}
                          onClick={() => {
                            setFullProductDetails({
                              ...fullProductDetails,
                              product: {
                                ...fullProductDetails.product,
                                vendor: matchVendor(vendor.name),
                              },
                            });
                            setTypeAheadText(vendor.name);
                            setVendorSelected(true);
                          }}
                        >
                          {vendor.name}
                        </li>
                      );
                    })
                : ""}
            </ul>

            <label htmlFor="vendorItemNumber">Vendor Item Number</label>
            <input
              name="vendorItemNumber"
              value={fullProductDetails.product.vendorItemNumber}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  product: {
                    ...fullProductDetails.product,
                    vendorItemNumber: e.target.value,
                  },
                });
              }}
              onBlur={() => {
                if (fullProductDetails.product.vendorItemNumber.length < 1) {
                  if (!errorArray.includes("vendorItemNumber")) {
                    setErrorArray([...errorArray, "vendorItemNumber"]);
                  }
                } else {
                  setErrorArray(
                    errorArray.filter((err) => {
                      return err !== "vendorItemNumber";
                    })
                  );
                }
              }}
            />

            <label htmlFor="standardCost">Standard Cost</label>
            <input
              name="standardCost"
              value={fullProductDetails.product.standardCost}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  product: {
                    ...fullProductDetails.product,
                    standardCost: e.target.value,
                  },
                });
              }}
              onBlur={() => {
                if (fullProductDetails.product.standardCost.length < 1) {
                  if (!errorArray.includes("standardCost")) {
                    setErrorArray([...errorArray, "standardCost"]);
                  }
                } else {
                  setErrorArray(
                    errorArray.filter((err) => {
                      return err !== "standardCost";
                    })
                  );
                }
              }}
            />

            <label htmlFor="basePrice">Base Price</label>
            <input
              name="basePrice"
              value={fullProductDetails.product.basePrice}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  product: {
                    ...fullProductDetails.product,
                    basePrice: e.target.value,
                  },
                });
              }}
              onBlur={() => {
                if (fullProductDetails.product.basePrice.length < 1) {
                  if (!errorArray.includes("basePrice")) {
                    setErrorArray([...errorArray, "basePrice"]);
                  }
                } else {
                  setErrorArray(
                    errorArray.filter((err) => {
                      return err !== "basePrice";
                    })
                  );
                }
              }}
            />

            <label htmlFor="minimumQuantity">Minumum Quantity</label>
            <input
              name="minimumQuantity"
              value={fullProductDetails.product.minimumQuantity}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  product: {
                    ...fullProductDetails.product,
                    minimumQuantity: e.target.value,
                  },
                });
              }}
              onBlur={() => {
                if (fullProductDetails.product.minimumQuantity.length < 1) {
                  if (!errorArray.includes("minimumQuantity")) {
                    setErrorArray([...errorArray, "minimumQuantity"]);
                  }
                } else {
                  setErrorArray(
                    errorArray.filter((err) => {
                      return err !== "minimumQuantity";
                    })
                  );
                }
              }}
            />

            <label htmlFor="maximumQuantity">Maxmimum Quantity</label>
            <input
              name="maximumQuantity"
              value={fullProductDetails.product.maximumQuantity}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  product: {
                    ...fullProductDetails.product,
                    maximumQuantity: e.target.value,
                  },
                });
              }}
              onBlur={() => {
                if (fullProductDetails.product.maximumQuantity.length < 1) {
                  if (!errorArray.includes("maximumQuantity")) {
                    setErrorArray([...errorArray, "maximumQuantity"]);
                  }
                } else {
                  setErrorArray(
                    errorArray.filter((err) => {
                      return err !== "maximumQuantity";
                    })
                  );
                }
              }}
            />

            <label htmlFor="defaultQuantity">Default Quantity</label>
            <input
              name="defaultQuantity"
              value={fullProductDetails.product.defaultQuantity}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  product: {
                    ...fullProductDetails.product,
                    defaultQuantity: e.target.value,
                  },
                });
              }}
              onBlur={() => {
                if (fullProductDetails.product.defaultQuantity.length < 1) {
                  if (!errorArray.includes("defaultQuantity")) {
                    setErrorArray([...errorArray, "defaultQuantity"]);
                  }
                } else {
                  setErrorArray(
                    errorArray.filter((err) => {
                      return err !== "defaultQuantity";
                    })
                  );
                }
              }}
            />

            <label htmlFor="imageAddress">Image Address</label>
            <input
              name="imageAddress"
              value={fullProductDetails.product.imageAddress}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  product: {
                    ...fullProductDetails.product,
                    imageAddress: e.target.value,
                  },
                });
              }}
              onBlur={() => {
                if (fullProductDetails.product.imageAddress.length < 1) {
                  if (!errorArray.includes("imageAddress")) {
                    setErrorArray([...errorArray, "imageAddress"]);
                  }
                } else {
                  setErrorArray(
                    errorArray.filter((err) => {
                      return err !== "imageAddress";
                    })
                  );
                }
              }}
            />

            <label htmlFor="category">Category</label>
            <select
              defaultValue
              value={fullProductDetails.product.category.name}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  product: {
                    ...fullProductDetails.product,
                    category: matchCategory(e.target.value),
                  },
                });
              }}
            >
              <option disabled value>
                {" "}
                -- select an option --{" "}
              </option>
              {categories.map((category) => {
                return <option key={category.name}>{category.name}</option>;
              })}
            </select>

            <label htmlFor="featured">Featured</label>
            <input
              type="checkbox"
              checked={fullProductDetails.product.featured}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  product: {
                    ...fullProductDetails.product,
                    featured: e.target.checked,
                  },
                });
              }}
            />

            <label htmlFor="onsale">On sale</label>
            <input
              type="checkbox"
              checked={fullProductDetails.product.onSale}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  product: {
                    ...fullProductDetails.product,
                    onSale: e.target.checked,
                  },
                });
              }}
            />

            <label htmlFor="instore">In Store Only</label>
            <input
              type="checkbox"
              checked={fullProductDetails.product.inStoreOnly}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  product: {
                    ...fullProductDetails.product,
                    inStoreOnly: e.target.checked,
                  },
                });
              }}
            />

            <label htmlFor="countryOfOrigin">Country Of Origin</label>
            <input
              name="countryOfOrigin"
              value={fullProductDetails.product.countryOfOrigin}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  product: {
                    ...fullProductDetails.product,
                    countryOfOrigin: e.target.value,
                  },
                });
              }}
            />
            <button
              className={errorArray.length > 0 ? "disabled" : ""}
              type="submit"
            >
              Submit changes
            </button>
          </form>

          <h2>Pricing Info:</h2>
          <form
            onSubmit={(e) => {
              updatePricing(e);
            }}
          >
            <label htmlFor="allowReturns">Allow returns</label>
            <input
              type="checkbox"
              checked={fullProductDetails.pricingInfo.allowReturns}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  pricingInfo: {
                    ...fullProductDetails.pricingInfo,
                    allowReturns: e.target.checked,
                  },
                });
              }}
            />

            <label htmlFor="taxable">Taxable</label>
            <input
              type="checkbox"
              checked={fullProductDetails.pricingInfo.taxable}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  pricingInfo: {
                    ...fullProductDetails.pricingInfo,
                    taxable: e.target.checked,
                  },
                });
              }}
            />

            <label htmlFor="federalTax">Federal Tax</label>
            <input
              type="checkbox"
              checked={fullProductDetails.pricingInfo.federalTax}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  pricingInfo: {
                    ...fullProductDetails.pricingInfo,
                    federalTax: e.target.checked,
                  },
                });
              }}
            />

            <label htmlFor="stateTax">State Tax</label>
            <input
              type="checkbox"
              checked={fullProductDetails.pricingInfo.stateTax}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  pricingInfo: {
                    ...fullProductDetails.pricingInfo,
                    stateTax: e.target.checked,
                  },
                });
              }}
            />

            <label htmlFor="localTax">Local Tax</label>
            <input
              type="checkbox"
              checked={fullProductDetails.pricingInfo.localTax}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  pricingInfo: {
                    ...fullProductDetails.pricingInfo,
                    localTax: e.target.checked,
                  },
                });
              }}
            />

            <label htmlFor="municipalTax">Municipal Tax</label>
            <input
              type="checkbox"
              checked={fullProductDetails.pricingInfo.municipalTax}
              onChange={(e) => {
                setFullProductDetails({
                  ...fullProductDetails,
                  pricingInfo: {
                    ...fullProductDetails.pricingInfo,
                    municipalTax: e.target.checked,
                  },
                });
              }}
            />
            <button
              className={errorArray.length > 0 ? "disabled" : ""}
              type="submit"
            >
              Submit changes
            </button>
          </form>

          <h3>DANGER ZONE</h3>
          <button
            onClick={() => {
              deleteProduct();
            }}
          >
            Delete product
          </button>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </section>
  );
};

export default EditItemForm;
