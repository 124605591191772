import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import ValidateToken from "./ValidateToken.js";
import UserContext from "../Contexts/UserContext.js";

function AdminRoute({ children, ...rest }) {
  const { user } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        ValidateToken() && user && user.user && user.user.isAdmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
export default AdminRoute;
