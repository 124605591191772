import React, { useEffect, useState } from "react";

import {
  authUpdateCustomer,
  authUpdateCustomerFinanceInfo,
  authDeleteUser,
  authGetCustomerFinanceInfo,
} from "../../Adapters/CustomerAdapters";
import StatesList from "../../Assets/StatesList.js";
import CustomerContacts from "./CustomerContacts";
import CustomerLocations from "./CustomerLocations";
import LoadingSpinner from "../LoadingSpinner.js";

const EditUsersForm = (props) => {
  const [basicCustomerInfo, setBasicCustomerInfo] = useState(props.user);
  const [financeInfo, setFinanceInfo] = useState([]);
  const [errorArray, setErrorArray] = useState([]);

  useEffect(() => {
    const getfinance = async () => {
      const financeList = await authGetCustomerFinanceInfo(props.user.key);
      setFinanceInfo(financeList.data);
    };
    getfinance();
  }, []);

  const submittedAlert = () => {
    window.alert("Submitted succesfully");
  };

  const goBack = () => {
    props.setUser({});
  };

  const updateBasicCustomerInfo = async (e) => {
    e.preventDefault();
    console.log(basicCustomerInfo);
    const updatedUser = {
      ...basicCustomerInfo,
      zipCode: parseInt(basicCustomerInfo.zipCode),
      phoneNumber: parseInt(basicCustomerInfo.phoneNumber),
      faxNumber: parseInt(basicCustomerInfo.faxNumber),
    };
    await authUpdateCustomer(updatedUser);
    submittedAlert();
  };

  const updateCustomerFinanceInfo = async (e) => {
    e.preventDefault();
    const updatedFinanceInfo = {
      ...financeInfo,
      statementFaxNumber: parseInt(financeInfo.statementFaxNumber),
      pricingTier: parseInt(financeInfo.pricingTier),
      creditLimit: parseInt(financeInfo.creditLimit),
    };
    await authUpdateCustomerFinanceInfo(props.user.key, updatedFinanceInfo);
    submittedAlert();
  };

  const checkSomeValue = (name) => {
    if (basicCustomerInfo[name].length === 0) {
      if (!errorArray.includes(name)) {
        setErrorArray([...errorArray, name]);
      }
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== name;
        })
      );
    }
  };

  const deleteUser = async () => {
    const confirm = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirm) {
      await authDeleteUser(props.user.key);
      goBack();
    }
  };

  const checkPhoneNumber = (key) => {
    if (/^[0-9]{10}$/gm.test(basicCustomerInfo[key])) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== key;
        })
      );
    } else {
      if (!errorArray.includes(key)) {
        setErrorArray([...errorArray, key]);
      }
    }
  };

  return (
    <section className="editUsersForm">
      {true ? (
        <>
          <ul className={errorArray.length > 0 ? "errors" : "hidden"}>
            {errorArray.map((err) => {
              return <li key={err}>{err}</li>;
            })}
          </ul>
          <button
            onClick={() => {
              goBack();
            }}
          >
            ←
          </button>
          <form
            onSubmit={(e) => {
              updateBasicCustomerInfo(e);
            }}
          >
            <h2>Customer Info</h2>
            <label htmlFor="name">Name:</label>
            <input
              value={basicCustomerInfo.name}
              name="name"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  name: e.target.value,
                });
              }}
              onBlur={() => {
                checkSomeValue("name");
              }}
            />

            <label htmlFor="address">Address:</label>
            <input
              value={basicCustomerInfo.address}
              name="address"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  address: e.target.value,
                });
              }}
              onBlur={() => {
                checkSomeValue("address");
              }}
            />

            <label htmlFor="city">City:</label>
            <input
              value={basicCustomerInfo.city}
              name="city"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  city: e.target.value,
                });
              }}
              onBlur={() => {
                checkSomeValue("city");
              }}
            />

            <label htmlFor="state">State:</label>
            <select
              defaultValue
              name="state"
              value={basicCustomerInfo.state}
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  state: e.target.value,
                });
              }}
            >
              <option disabled value>
                {" "}
                -- select an option --{" "}
              </option>
              {StatesList.map((state) => {
                return <option key={state}>{state}</option>;
              })}
            </select>

            <label htmlFor="phoneNumber">Phone Number:</label>
            <input
              value={basicCustomerInfo.phoneNumber}
              name="phoneNumber"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  phoneNumber: e.target.value,
                });
              }}
              onBlur={() => {
                checkPhoneNumber("phoneNumber");
              }}
            />
            <label htmlFor="faxNumber"> Fax Number:</label>
            <input
              value={basicCustomerInfo.faxNumber}
              name="faxNumber"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  faxNumber: e.target.value,
                });
              }}
              onBlur={() => {
                checkPhoneNumber("faxNumber");
              }}
            />

            <label htmlFor="zipCode"> Zipcode:</label>
            <input
              value={basicCustomerInfo.zipCode}
              name="zipCode"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  zipCode: e.target.value,
                });
              }}
              onBlur={() => {
                checkSomeValue("zipCode");
              }}
            />

            <label htmlFor="password"> Password:</label>
            <input
              value={basicCustomerInfo.password}
              name="password"
              type="password"
              onChange={(e) => {
                setBasicCustomerInfo({
                  ...basicCustomerInfo,
                  password: e.target.value,
                });
              }}
              onBlur={() => {
                checkSomeValue("password");
              }}
            />

            <button
              className={errorArray.length > 0 ? "disabled" : ""}
              type="submit"
            >
              Submit changes
            </button>
          </form>

          <h2>Customer Contact</h2>
          <CustomerContacts userKey={props.user.key} />

          <h2> Customer Location </h2>
          <CustomerLocations userKey={props.user.key} />

          <form
            onSubmit={(e) => {
              updateCustomerFinanceInfo(e);
            }}
          >
            <h2>Customer Finance Info</h2>
            <div className="financeInfoRow">
              <div className="financeInfoColumn">
                <label htmlFor="isTaxable">Is Taxable:</label>
                <input
                  type="checkbox"
                  checked={financeInfo.isTaxable}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      isTaxable: e.target.checked,
                    });
                  }}
                />
                <label htmlFor="isOnAccount">Is On Account:</label>
                <input
                  type="checkbox"
                  checked={financeInfo.isOnAccount}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      isOnAccount: e.target.checked,
                    });
                  }}
                />
                <label htmlFor="hasStatement">Has Statement:</label>
                <input
                  type="checkbox"
                  checked={financeInfo.hasStatement}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      hasStatement: e.target.checked,
                    });
                  }}
                />
                <label htmlFor="hasLateCharges">Has Late Charges:</label>
                <input
                  type="checkbox"
                  checked={financeInfo.hasLateCharges}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      hasLateCharges: e.target.checked,
                    });
                  }}
                />
                <label htmlFor="autoSendStatements">
                  Auto Send Statements:
                </label>
                <input
                  type="checkbox"
                  checked={financeInfo.autoSendStatements}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      autoSendStatements: e.target.checked,
                    });
                  }}
                />
                <label htmlFor="hasCreditCard">Has Credit Card:</label>
                <input
                  type="checkbox"
                  checked={financeInfo.hasCreditCard}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      hasCreditCard: e.target.checked,
                    });
                  }}
                />
                <label htmlFor="preBill">Pre-Bill:</label>
                <input
                  type="checkbox"
                  checked={financeInfo.preBill}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      preBill: e.target.checked,
                    });
                  }}
                />
                <label htmlFor="requiresShipTo">Requires Ship To:</label>
                <input
                  type="checkbox"
                  checked={financeInfo.requiresShipTo}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      requiresShipTo: e.target.checked,
                    });
                  }}
                />
                <label htmlFor="requiresPayment">Requires Payment:</label>
                <input
                  type="checkbox"
                  checked={financeInfo.requiresPayment}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      requiresPayment: e.target.checked,
                    });
                  }}
                />
                <label htmlFor="canHaveBackOrders">Can Have Back Orders:</label>
                <input
                  type="checkbox"
                  checked={financeInfo.canHaveBackOrders}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      canHaveBackOrders: e.target.checked,
                    });
                  }}
                />
              </div>
              <div className="financeInfoColumn">
                <label htmlFor="shipCompleteOnly">Ship Complete Only:</label>
                <input
                  type="checkbox"
                  checked={financeInfo.shipCompleteOnly}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      shipCompleteOnly: e.target.checked,
                    });
                  }}
                />
                <label htmlFor="hasBreakoutPricing">
                  Has Breakout Pricing:
                </label>
                <input
                  type="checkbox"
                  checked={financeInfo.hasBreakoutPricing}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      hasBreakoutPricing: e.target.checked,
                    });
                  }}
                />
                <label htmlFor="statementEmail">Statement Email:</label>
                <input
                  value={financeInfo.statementEmail}
                  name="statementEmail"
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      statementEmail: e.target.value,
                    });
                  }}
                />
                <label htmlFor="statementFaxNumber">
                  Statement Fax Number:
                </label>
                <input
                  value={financeInfo.statementFaxNumber}
                  name="statementFaxNumber"
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      statementFaxNumber: e.target.value,
                    });
                  }}
                />

                <label htmlFor="taxAuthority">Tax Authority:</label>
                <select
                  defaultValue
                  name="state"
                  value={financeInfo.taxAuthority}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      taxAuthority: e.target.value,
                    });
                  }}
                >
                  <option disabled value>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  {StatesList.map((state) => {
                    return <option key={state}>{state}</option>;
                  })}
                </select>

                <label htmlFor="terms">Terms:</label>
                <select
                  defaultValue
                  name="terms"
                  value={financeInfo.terms}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      terms: e.target.value,
                    });
                  }}
                >
                  <option disabled value>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  <option>COD</option>;<option>NET30</option>;
                  <option>NET45</option>;<option>NET50</option>;
                </select>

                <label htmlFor="creditLimit">Credit Limit:</label>
                <input
                  value={financeInfo.creditLimit}
                  name="creditLimit"
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      creditLimit: e.target.value,
                    });
                  }}
                />

                <label htmlFor="paymentMethod">Payment Method:</label>
                <select
                  defaultValue
                  name="paymentMethod"
                  value={financeInfo.paymentMethod}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      paymentMethod: e.target.value,
                    });
                  }}
                >
                  <option disabled value>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  <option>CC</option>;<option>ACCT</option>;
                </select>

                <label htmlFor="pricingTier">Pricing Tier:</label>
                <select
                  defaultValue
                  name="pricingTier"
                  value={financeInfo.pricingTier}
                  onChange={(e) => {
                    setFinanceInfo({
                      ...financeInfo,
                      pricingTier: e.target.value,
                    });
                  }}
                >
                  <option disabled value>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  <option>0</option>;<option>1</option>;<option>2</option>;
                  <option>3</option>;
                </select>
              </div>
            </div>

            <button
              className={errorArray.length > 0 ? "disabled" : ""}
              type="submit"
            >
              Submit changes
            </button>
          </form>

          <h3>DANGER ZONE</h3>
          <button onClick={deleteUser}>Delete customer</button>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </section>
  );
};

export default EditUsersForm;
