import React, { useState, useEffect } from "react";

import { CustomerContact } from "../../Assets/UtilityClasses.js";

const Contact = (props) => {
  const [errorArray, setErrorArray] = useState([]);
  const [formValues, setFormValues] = useState(new CustomerContact());
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setFormValues({ ...props.contact });
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const checkPhoneNumber = () => {
    if (/^[0-9]{10}$/gm.test(formValues.phoneNumber)) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "phoneNumber";
        })
      );
    } else {
      if (!errorArray.includes("phoneNumber")) {
        setErrorArray([...errorArray, "phoneNumber"]);
      }
    }
  };

  const checkSomeValue = (name) => {
    if (formValues[name].length === 0) {
      if (!errorArray.includes(name)) {
        setErrorArray([...errorArray, name]);
      }
    } else {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== name;
        })
      );
    }
  };

  const checkEmail = () => {
    if (/(@)(.+)$/gm.test(formValues.email)) {
      setErrorArray(
        errorArray.filter((err) => {
          return err !== "email";
        })
      );
    } else {
      setErrorArray([...errorArray, "email"]);
    }
  };

  const handleEditing = async () => {
    if (
      isEditing &&
      JSON.stringify(formValues) !== JSON.stringify(props.contact)
    ) {
      props.updateContact({
        ...formValues,
        customerKey: parseInt(props.contact.customerKey),
        key: parseInt(props.contact.key),
        phoneNumber: parseInt(formValues.phoneNumber),
      });
    }
    setIsEditing(!isEditing);
    setErrorArray([]);
  };

  return (
    <>
      {errorArray.length > 0 && isEditing ? (
        <tr className="error">
          <td>Invalid values in:</td>
          {errorArray.map((cv) => {
            return <td key={cv}>{cv}</td>;
          })}
        </tr>
      ) : null}
      <tr key={props.contact.key}>
        {isEditing ? (
          <>
            <td>
              <input
                value={formValues.name}
                name="name"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={() => {
                  checkSomeValue("name");
                }}
              />
            </td>
            <td>
              <input
                value={formValues.phoneNumber}
                name="phoneNumber"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={() => {
                  checkPhoneNumber();
                }}
              />
            </td>
            <td>
              <input
                value={formValues.email}
                name="email"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={() => {
                  checkEmail();
                }}
              />
            </td>
          </>
        ) : (
          <>
            <td>{props.contact.name}</td>
            <td>{props.contact.phoneNumber}</td>
            <td>{props.contact.email}</td>
          </>
        )}
        <td>
          <button
            onClick={() => {
              handleEditing();
            }}
          >
            ✎
          </button>
        </td>
        <td>
          <button
            onClick={() => {
              props.deleteContact(props.contact.key);
            }}
          >
            ❌
          </button>
        </td>
      </tr>
    </>
  );
};

export default Contact;
