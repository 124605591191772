import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { authGetFavorites } from "../../Adapters/ProductAdapters.js";
import NoImage from "../../Assets/NoImage.js";

import "../../Styles/AccountPage/FavoritesList.scss";

const FavoritesList = (props) => {
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    const getfavorites = async () => {
      const favList = await authGetFavorites();
      setFavorites(favList.data);
    };
    getfavorites();
  }, []);
  return (
    <section className="favoritesList">
      {favorites.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th>Product ID</th>
            </tr>
          </thead>
          <tbody>
            {favorites.map((fav) => {
              return (
                <tr key={fav.key}>
                  <td>
                    <img
                      src={fav.imageAddress ? fav.imageAddress : NoImage()}
                      alt="product"
                      onClick={() => {
                        props.history.push(`/product/${fav.key}`);
                      }}
                    />
                  </td>
                  <td>{fav.description}</td>
                  <td>{fav.productId}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <h3>Oops, looks like we don't have anything to see here.</h3>
      )}
    </section>
  );
};

export default withRouter(FavoritesList);
